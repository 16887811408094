.lightbox {
	color: white;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.9);
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	box-sizing: border-box;
	z-index: 998;

	&.hide{
		display: none;
	}

	&__close {
		
		top: 1rem;
		right: 1rem;
	}

	&__prev,
	&__next {
		top: 50%;
	}

	&__prev,
	&__next,
	&__close {
		@include transition();
		position: absolute;
		z-index: 2;

		&:hover {
			color: get-color(brand-green, 2);
			cursor: pointer;
		}
	}
	&__prev {
		left: 2rem;
	}
	&__next {
		right: 2rem;
	}

	&__numb {
		position: absolute;
		bottom: 4vh;
	}

	&__inner {
		z-index: -1;
		overflow: hidden;
		color: white;
		border-radius: 1rem;
		position: absolute;
		justify-content: center;
		max-width: 80vw;
		max-height: 80vh;

		img {
			position: relative;
			width: 100%;
			height: auto;
		}
	}
}
