.input-wrapper {
	input {
		display: flex;
		padding: 1.25rem 1.5rem;
		align-items: center;
		border-radius: 1rem;
		border: 2px solid transparent;

		@include transition(border);

		&:focus {
			border: 2px solid get-color(neutral, 3);
		}

		&::placeholder {
			font-size: clamp(1rem, 0.466vw + 0.806rem, 1rem);
			color: get-color(ink, 1);
			font-weight: 200;
			width: 100%;
		}

		&:disabled {
			background-color: get-color(neutral, 3);
		}
	}

	.helper {
		display: none;
		margin-top: -0.5rem;

		@include transition(display);

		&.active {
			display: block;
		}
	}
}

.just-validate-error-field {
  border: 2px solid get-color(red, 3)!important;
  @include transition();
}

.just-validate-error-label {
  @include transition();
}