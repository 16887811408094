.gallery{
    &-thumb{
        position: relative;
        border-radius: 1rem;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        column-gap: 3rem;
        aspect-ratio: 1;

        img{
            @include transition();
        }

        &:hover > img{
            transform: scale(1.1);
        }
    }
}