.checkbox-wrapper {
	.checkbox {
		display: flex;
		flex-direction: row;
		gap: 1rem;
		user-select: none;
		text-transform: unset;
		letter-spacing: 0;
		cursor: pointer;

		&:hover > .checkmark {
			border: 1px solid get-color(neutral, 5);
		}

		.checkmark {
			@include transition();

			border: 1px solid get-color(neutral, 3);
			border-radius: 0.5rem;
			width: 1.5rem;
			height: 1.5rem;
			background-color: white;
			color: get-color(brand-purple, 2);

			.icon {
				position: absolute;
				z-index: 2;
				opacity: 0;

				@include transition();
			}
		}
	}

    .checkbox-trigger {
			display: none;

			&:checked + .checkbox > .checkmark  {
				background-color: get-color(brand-purple, 2);
                border: 1px solid transparent;
			}

			&:checked + .checkbox > .checkmark > .icon {
				opacity: 1;
			}
		}
}
