.reference-filter {
	height: 10rem;
	padding: 0 4rem;
	background-color: get-color(neutral, 1);
	border-radius: 2rem;
	gap: 8rem;
    justify-content: space-between;

	ul {
		list-style: none;
        gap: 4rem;
        flex-shrink: 1;
        height: 100%;
        justify-content: space-around;


		li{
            display: flex;
            align-content: center;
            align-items: center;
            height: 100%;
            justify-content: center;

            @include transition();

            &:hover{
                cursor: pointer;
                color: get-color(brand-green,3);
            }

            &.active{
                font-weight: 600;
                color: get-color(brand-green,3);
                border-bottom: 2px solid get-color(brand-green,3);
            }
        }
	}
}
