.transition-section {
	position: fixed;
	top: 0;
	z-index: 999;
	width: 100vw;
	height: 100svh;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	background-color: get-color(brand-purple, 3);

	svg {
		position: absolute;
		width: calc(20vw);
		min-width: 8rem;
	}
}