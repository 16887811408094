a.buttons,
.buttons {
	@extend .button-label;

	@include transition();

	transform-origin: "center center";
	border-color: transparent;
	display: flex;
	padding: 1rem 3rem;
	align-items: center;
	align-content: center;
	border-radius: 3rem;
	width: fit-content;
	text-align: center;
	text-wrap: nowrap;
	min-width: 6rem;

	&:hover {
		transform: scale(1.03);
		cursor: pointer;
	}

	// =============================================================================
	// Text To Icon
	// =============================================================================

	&.change-to-icon {
		@include media-breakpoint-down(lg) {
			padding: 0;
			width: 5rem;
			height: 5rem;
			min-width: auto;

			.label {
				display: none;
			}
			.icon {
				display: flex !important;
			}
		}

		@include media-breakpoint-down(lg) {
			padding: 0;
			width: 4rem;
			height: 4rem;
			min-width: auto;
		}
	}

	// =============================================================================
	// Primary Button
	// =============================================================================

	&.primary-button {
		background-color: get-color(brand-purple, 3);

		&,
		& > span {
			color: white;
		}

		&:hover {
			background-color: get-color(brand-purple, 2);
		}

		&.outlined {
			border: 2px solid get-color(brand-purple, 3);
			background-color: transparent;

			&,
			& > span {
				color: get-color(brand-purple, 3);
			}

			&:hover {
				background-color: get-color(brand-purple, 3);

				&,
				& > span {
					color: white;
				}
			}
		}
	}

	// =============================================================================
	// White Button
	// =============================================================================

	&.white-button {
		background-color: white;

		&,
		& > span {
			color: get-color(brand-purple, 3);
		}

		&:hover {
			background-color: get-color(neutral, 2);

			&,
			& > span {
				color: get-color(brand-purple, 3);
			}
		}

		&.outlined {
			border: 2px solid white;
			background-color: transparent;

			&,
			& > span {
				color: white;
			}

			&:hover {
				background-color: white;

				&,
				& > span {
					color: get-color(brand-purple, 3);
				}
			}
		}
	}
}

a.whatsapp-btn {
	position: fixed;
	width: 4rem;
	height: auto;
	bottom: 0;
	right: 0;
	z-index: 99;

	svg {
		width: 100%;
		height: 100%;
	}
}
