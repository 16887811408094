.input-wrapper {
	select {
		background-image: url( "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M16.263 8L7.73703 8C6.13964 8 5.18686 9.78029 6.07293 11.1094L10.3359 17.5038C11.1275 18.6913 12.8725 18.6913 13.6641 17.5039L17.9271 11.1094C18.8131 9.78029 17.8604 8 16.263 8Z'/%3E%3C/svg%3E" );
		background-repeat: no-repeat;
      	background-size: cover;
      	background-position: bottom center, 50%, 50%;
		
		background-size: 1rem;
		background-position: calc(100% - 1rem);
        background-color: white;
		appearance: none;

		padding: 1.25rem 1.5rem;
		border-radius: 1rem;
		border: 2px solid transparent;
		font-size: clamp(1rem, 0.466vw + 0.806rem, 1rem);
		color: get-color(ink, 3);
		font-weight: 200;

		@include transition(border);

		&:focus {
			border: 2px solid get-color(neutral, 3);
		}

		&:invalid {
			color: get-color(ink, 1);
		}

		&select:hover {
			cursor: pointer;
		}
	}

	.helper {
		display: none;
		margin-top: -0.5rem;

		@include transition(display);

		&.active {
			display: block;
		}
	}
}
