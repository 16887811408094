.organization {
	background-color: get-color(neutral, 1);
	border-radius: 2rem;
	padding: 6rem 0;

    .group-container{
        display: flex;
        flex-direction: column;
        margin-top: 4rem;
        gap: 3rem;
        align-items: center;
    }

	.content-wrapper {
		width: fit-content;
		background-color: white;
		padding: 2.5rem;
		border-radius: 0.5rem;

		.text-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			align-content: center;
			justify-content: center;

            .title{
                margin-top: 2rem;
                display: flex;
                flex-direction: column;
                position: relative;
                text-align: center;
                width: 100%;

                &::before{
                    margin-bottom: 1rem;
                    top: 0;
                    left: 0;
                    width: 100%;
                    content: '';
                    border: 1px solid get-color(neutral,1);
                }
            }
		}
	}
}