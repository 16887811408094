.flex-row {
	display: flex;
	flex-direction: row;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.align-middle {
	align-items: center;
	align-content: center;
}

.section,
section {
	width: 100%;
	position: relative;

	//border: 1px solid green;
}

[class*="wrapper"] {
	//border: 2px solid black;
}

.row {
	height: fit-content;
}

.content-wrapper{
	position: relative;
}