html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  display: block;
  overflow-x: hidden;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button;
}

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring, button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox], [type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

a, button {
  color: inherit;
  text-decoration: inherit;
}

ul {
  margin: 0;
  padding: 0;
}

*:focus {
  outline: none;
}

body {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

/* main::-webkit-scrollbar{display: none;} */
.styles .container {
  border: 1px solid red;
}
.styles .container [class*=col] {
  border: 2px solid black;
}

/*!
 * Bootstrap Grid v5.3.2 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 3rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 96vw;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 96vw;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 96vw;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 96vw;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 96vw;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 3rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

@media (min-width: 576px) {
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 992px) {
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
@font-face {
  font-family: "Poppins";
  font-weight: 300;
  src: url("../../assets/fonts/poppins/Poppins-Light.woff") format("woff");
}
@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: url("../../assets/fonts/poppins/Poppins-Regular.woff") format("woff");
}
@font-face {
  font-family: "Poppins";
  font-weight: 600;
  src: url("../../assets/fonts/poppins/Poppins-SemiBold.woff") format("woff");
}
.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.text-thin {
  font-weight: 300;
}

.text-regular, .button-label, a.buttons,
.buttons {
  font-weight: 500;
}

.text-semibold, .menu-text {
  font-weight: 600;
}

.text-italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline;
}

.text-uppercase, .button-label, a.buttons,
.buttons, .label,
label, .menu-text, .caption, .overline {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-color-white {
  color: white;
}

.text-color-black {
  color: black;
}

.text-color-brand-purple-3 {
  color: get-color(brand-purple, 3);
}

.text-color-brand-purple-2 {
  color: get-color(brand-purple, 2);
}

.text-color-brand-purple-1 {
  color: get-color(brand-purple, 1);
}

.text-color-brand-green-3 {
  color: get-color(brand-green, 3);
}

.text-color-brand-green-2 {
  color: get-color(brand-green, 2);
}

.text-color-brand-green-1 {
  color: get-color(brand-green, 1);
}

.text-color-ink-3 {
  color: get-color(ink, 3);
}

.text-color-ink-2 {
  color: get-color(ink, 2);
}

.text-color-ink-1 {
  color: get-color(ink, 1);
}

.text-color-error {
  color: get-color(red, 3);
}

* {
  font-family: "Poppins";
}

.text-hero {
  font-size: clamp(3.125rem, 2.354vw + 2.835rem, 6.5rem);
  line-height: 1.1;
}

.text-huge {
  font-size: clamp(4.125rem, 12.304vw + 3.235rem, 15rem);
}

h1,
.h1 {
  font-size: clamp(3.125rem, 7.304vw + 1.235rem, 10rem);
}

h2,
.h2 {
  font-size: clamp(2.5rem, 5.843vw + 0.988rem, 8rem);
}

h3,
.h3 {
  font-size: clamp(2.5rem, 3.062vw + 0.225rem, 5.5rem);
}

h4,
.h4 {
  font-size: clamp(2rem, 1.594vw + 1.588rem, 4rem);
}

h5,
.h5 {
  font-size: clamp(1.25rem, 0.797vw + 1.044rem, 2rem);
}

h6,
.h6 {
  font-size: clamp(1.25rem, 1.594vw + 1.588rem, 1.75rem);
}

p,
.p {
  font-size: clamp(1rem, 0.466vw + 0.806rem, 1.125rem);
}

p.small,
.p.small {
  font-size: clamp(0.75rem, 0.133vw + 0.716rem, 1.875rem);
}

p.large,
.p.large {
  font-size: clamp(1.25rem, 0.531vw + 1.113rem, 1.75rem);
}

.overline {
  font-size: clamp(0.563rem, 0.199vw + 0.511rem, 0.75rem);
  letter-spacing: 0.225rem;
}

.caption {
  font-size: clamp(0.688rem, 0.133vw + 0.653rem, 0.813rem);
}

.menu-text {
  font-size: clamp(0.588rem, 3.199vw + 0.753rem, 0.85rem);
  letter-spacing: 0.1rem;
}
@media (max-width: 1199.98px) {
  .menu-text {
    font-size: 3rem;
    letter-spacing: 0.3rem;
  }
}
@media (max-width: 991.98px) {
  .menu-text {
    font-size: 1.8rem;
    letter-spacing: 0.2rem;
  }
}
@media (max-width: 767.98px) {
  .menu-text {
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
  }
}

.label,
label {
  font-size: clamp(0.588rem, 3.199vw + 0.753rem, 0.85rem);
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: #5a555e;
}

.button-label, a.buttons,
.buttons {
  font-size: clamp(1rem, 0.9642857143rem + 0.1785714286vw, 1rem);
  line-height: 2;
  letter-spacing: 0.1rem;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
h6 > *,
.h6 *,
.h6,
p,
p * {
  line-height: 1.6;
  margin: 0;
  padding: 0;
  max-width: 100%;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.text-thin {
  font-weight: 300;
}

.text-regular, .button-label, a.buttons,
.buttons {
  font-weight: 500;
}

.text-semibold, .menu-text {
  font-weight: 600;
}

.text-italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline;
}

.text-uppercase, .overline, .caption, .menu-text, .label,
label, .button-label, a.buttons,
.buttons {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-color-white {
  color: white;
}

.text-color-black {
  color: black;
}

.text-color-brand-purple-3 {
  color: #210e39;
}

.text-color-brand-purple-2 {
  color: #2d134e;
}

.text-color-brand-purple-1 {
  color: #470080;
}

.text-color-brand-green-3 {
  color: #00a87f;
}

.text-color-brand-green-2 {
  color: #24e0b1;
}

.text-color-brand-green-1 {
  color: #4effd3;
}

.text-color-ink-3 {
  color: #1b0d26;
}

.text-color-ink-2 {
  color: #5a555e;
}

.text-color-ink-1 {
  color: #a7a2ac;
}

.text-color-error {
  color: #d93a43;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-middle {
  align-items: center;
  align-content: center;
}

.section,
section {
  width: 100%;
  position: relative;
}

.row {
  height: fit-content;
}

.content-wrapper {
  position: relative;
}

.opacity-64 {
  opacity: 0.64;
}

.opacity-80 {
  opacity: 0.8;
}

.vh-80 {
  height: 80vh;
}

.vh-100 {
  height: 100vh;
}

.width-85 {
  width: 85%;
}

.mt-8rem {
  margin-top: 8rem;
}

.mt-13rem {
  margin-top: 13rem;
}

.flex-end {
  align-items: flex-end;
}

.h-center {
  flex-direction: row;
  justify-content: center;
}

.v-center {
  flex-direction: column;
  justify-content: center;
}

.img-cover {
  width: 100%;
  height: auto;
  min-height: 100%;
  position: absolute;
  object-fit: cover;
}

div:has(> .img-cover) {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
}

img.square {
  aspect-ratio: 1;
}

.rounded-mid {
  border-radius: 2rem;
}

.gap-05rem {
  gap: 0.5rem !important;
}

.gap-1rem {
  gap: 1rem !important;
}

.gap-2rem {
  gap: 2rem !important;
}

.gap-3rem {
  gap: 3rem !important;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

svg {
  overflow: visible;
}

.row-gap-3rem {
  row-gap: 3rem;
}

.noClick {
  pointer-events: none;
}

.noClickParent > * {
  pointer-events: none;
}

a:hover {
  cursor: pointer;
}

.hide {
  display: none !important;
}

.j-center {
  justify-content: center;
}

.border-box {
  box-sizing: border-box;
}

.h-100p {
  height: 100%;
}

.w-100p {
  width: 100%;
}

/* =================================== */
@media (min-width: 1200px) {
  .hide-laptop {
    display: none !important;
  }
}

@media (max-width: 1199.98px) {
  .hide-tablet-landscape {
    display: none !important;
  }
}

@media (max-width: 991.98px) {
  .hide-tablet-portrait {
    display: none !important;
  }
}

@media (max-width: 767.98px) {
  .hide-mobile-landscape {
    display: none !important;
  }
}

@media (max-width: 575.98px) {
  .hide-mobile-portrait {
    display: none !important;
  }
}

/* *** */
@media (max-width: 575.98px) {
  .show-mobile-portrait {
    display: block !important;
  }
}

@media (max-width: 767.98px) {
  .show-mobile-landscape {
    display: block !important;
  }
}

@media (max-width: 991.98px) {
  .show-tablet-portrait {
    display: block !important;
  }
}

@media (max-width: 1199.98px) {
  .show-tablet-landscape {
    display: block !important;
  }
}

/* =================================== */
.guideBorder {
  border: 1px solid red;
}

.guideBg {
  background-color: red;
}

/* =================================== */
html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

.hero {
  position: relative;
  margin-top: 2vw;
  height: calc(100vh - 4vw);
  width: 96vw;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
@media (max-width: 1199.98px) {
  .hero {
    height: calc(100svh - 4vw);
  }
}
.hero #hero-mask,
.hero #subpage-hero-mask {
  display: flex;
  position: absolute;
}
.hero .slider-container {
  height: calc(100vh - 4vw);
  width: 96vw;
  overflow: hidden;
  z-index: 0;
}
@media (max-width: 1199.98px) {
  .hero .slider-container {
    height: calc(100svh - 4vw);
  }
}
.hero .slider-container .slider-wrapper {
  height: 100%;
}
.hero .slider-container .slider-items {
  height: 100%;
  position: relative;
}
.hero .slider-container .slider-items .slider-item {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
}
.hero .slider-container .slider-items .slider-item .slider-text-wrapper {
  position: absolute;
  z-index: 2;
  height: 100%;
  max-width: 40%;
  margin-left: 4vw;
  gap: 2rem;
  justify-content: center;
}
@media (max-width: 1199.98px) {
  .hero .slider-container .slider-items .slider-item .slider-text-wrapper {
    box-sizing: border-box;
    max-width: 95%;
    justify-content: end;
    height: 85%;
  }
}
@media (max-width: 575.98px) {
  .hero .slider-container .slider-items .slider-item .slider-text-wrapper h2 {
    font-size: 16vw;
  }
}
@media (max-width: 1199.98px) {
  .hero .slider-container .slider-items .slider-item .slider-text-wrapper h2 {
    font-size: 8vw;
  }
}
@media (max-width: 575.98px) {
  .hero .slider-container .slider-items .slider-item .slider-text-wrapper h2 {
    font-size: 12vw;
  }
}
.hero .slider-container .slider-items .slider-item .slider-text-wrapper a {
  position: relative;
  z-index: 2;
}
@media (max-width: 575.98px) {
  .hero .slider-container .slider-items .slider-item .slider-text-wrapper a {
    display: none;
  }
}
.hero .slider-container .slider-items .slider-item .slider-dragger {
  position: absolute;
  width: 100vw;
  height: 100%;
  z-index: 1;
  left: -5vw;
  top: 0;
}
.hero .slider-container .slider-items .slider-item.active {
  display: block;
  z-index: 5;
}
.hero .slider-container .slider-items .slider-item.actived {
  display: block;
  z-index: 2;
}
.hero .slider-container .slider-items .slider-item .img-wrapper {
  top: 0;
  width: 100%;
  height: auto;
  min-height: 103%;
  position: absolute;
  object-fit: cover;
}
.hero .slider-container .slider-items .slider-item .img-wrapper img {
  width: 100%;
  height: auto;
  min-height: 103%;
}
@media (max-width: 991.98px) {
  .hero .slider-container .slider-items .slider-item .img-wrapper img {
    bottom: 0;
  }
}
.hero-bottom {
  height: calc((100vh - 4vw) * 0.1);
  width: 96vw;
  box-sizing: border-box;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  z-index: 8;
}
.hero-bottom ul {
  list-style-type: none;
  padding: 0 2vw;
  gap: 0.5vw;
}
.hero-bottom ul li {
  width: 2rem;
  height: 2vw;
  justify-content: center;
}
.hero-bottom .scroll-down {
  box-sizing: border-box;
  padding: 1svw 2vw;
  justify-content: center;
}
.hero-bottom .scroll-down svg {
  transform: scale(1.2);
  width: 80%;
  height: auto;
}

@media (max-width: 575.98px) {
  .slider-buttons {
    position: absolute;
    right: 0;
  }
}
.slider-buttons .slider-button {
  position: relative;
  background-color: #dfdde1;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 10rem;
  transition: transform 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
.slider-buttons .slider-button:hover {
  cursor: pointer;
  transform: scale(1.3);
}
.slider-buttons .slider-button.active {
  background-color: #24e0b1;
  width: 0.8rem;
  height: 0.8rem;
}
.slider-buttons .slider-button.active:hover {
  transform: scale(1);
}

.scroll-down-ani {
  transition: transform 0.4s cubic-bezier(0, 0.25, 0.25, 1);
  animation: SDAni forwards 2s infinite;
}

@keyframes SDAni {
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(-5px);
  }
}
.subpage-hero-img-container {
  width: 96%;
  aspect-ratio: 1800/1012;
}
.subpage-hero-img-container .hero-subpage-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  aspect-ratio: 1800/1012;
}
.subpage-hero-img-container .hero-subpage-wrapper img {
  min-width: 100%;
  height: auto;
  aspect-ratio: 1800/1012;
}
.subpage-hero-img-container .subpage-hero-SVG {
  position: absolute;
}

.nav {
  position: relative;
  z-index: 10;
  background-color: white;
}
.nav.underBorder {
  border-bottom: 1px solid #ecebed;
}
.nav.underBorder:hover {
  border-bottom: 1px solid transparent;
}
.nav .logo-wrapper {
  position: relative;
}
.nav .logo-wrapper svg {
  width: 10vw;
  min-height: 48px;
}
@media (max-width: 1199.98px) {
  .nav .logo-wrapper svg {
    width: 15vw;
  }
}
@media (max-width: 991.98px) {
  .nav .logo-wrapper svg {
    width: 20vw;
  }
}
.nav.sticky {
  padding: 1rem 0;
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 99;
  background-color: white;
  border-bottom: 1px solid #ecebed;
}
.nav.subpage {
  width: 100vw;
  padding: 1rem 0;
  border-bottom: 1px solid #ecebed;
  background-color: white;
}
.nav .nav-wrapper {
  position: relative;
  box-sizing: border-box;
  padding: 0 0 0 2.2vw;
  width: 100%;
  justify-content: space-between;
}
.nav .nav-wrapper .menu-wrapper {
  position: relative;
  height: 100%;
}
@media (max-width: 1199.98px) {
  .nav .nav-wrapper .menu-wrapper {
    box-sizing: unset !important;
    padding: 5vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
}
.nav .nav-wrapper .menu-wrapper ul {
  list-style-type: none;
  justify-content: space-between;
  padding: 1rem 3vw;
  gap: 2rem;
}
.nav .nav-wrapper .menu-wrapper ul li a {
  transition: color 0.2s cubic-bezier(0, 0.25, 0.25, 1);
  padding: 1rem 0;
}
.nav .nav-wrapper .menu-wrapper ul li a.menu-active {
  border-bottom: 2px solid #00a87f;
  color: #00a87f;
}
.nav .nav-wrapper .menu-wrapper ul li a:hover {
  color: #00a87f;
}
.nav .nav-wrapper .menu-wrapper ul li:last-child > a {
  margin: 0;
}
.nav.mainpage {
  background-color: transparent;
  position: absolute;
  z-index: 10;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  top: calc(100svh - (100svh - 2svw));
}
.nav.mainpage .logo-wrapper {
  top: 1svw;
  position: relative;
}
@media (max-width: 575.98px) {
  .nav.mainpage .logo-wrapper {
    top: 0svw;
    left: 4svw;
  }
}
@media (max-width: 575.98px) {
  .nav.mainpage .logo-wrapper svg {
    width: 40svw;
  }
}
.nav.mainpage .nav-wrapper {
  margin: auto 3vw auto 3vw;
  height: 100%;
}
.nav.mainpage .nav-wrapper .menu-wrapper {
  width: 50.88vw;
  height: 10vh;
}
@media (max-width: 1199.98px) {
  .nav.mainpage .nav-wrapper .menu-wrapper {
    width: fit-content;
    padding: 2rem;
    height: 2rem;
  }
}
.nav.mainpage .nav-wrapper .menu-wrapper ul:not(ul > li > ul) {
  gap: 0;
  align-items: center;
}
.nav.mainpage .nav-wrapper .menu-wrapper li.menu-text {
  height: fit-content;
}
.nav .sub-menu-link-wrapper {
  opacity: 0;
  display: none;
  top: 2.6rem;
  position: absolute;
  gap: 1.2rem !important;
  z-index: 10;
  padding: 3rem !important;
  font-weight: 500;
  font-size: 0.85rem;
  letter-spacing: 0.05rem;
  background-color: white;
  border-radius: 1.5rem;
  box-shadow: 0px 1.4px 13px 0px rgba(0, 0, 0, 0.05), 0px 7px 80px 0px rgba(0, 0, 0, 0.09);
  transition: all 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
.nav .sub-menu-link-wrapper li:not(:last-child) {
  border-bottom: 1 solid #ecebed;
}
.nav .sub-menu-link-wrapper li {
  height: 1.5rem;
  white-space: nowrap;
  transition: transform 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
.nav .sub-menu-link-wrapper li:hover {
  transform: translate(0.5rem, 0);
}
.nav .sub-menu-link-wrapper li a {
  width: 100%;
}
.nav .sub-menu-link-wrapper li a.menu-active {
  border-bottom: 0 !important;
}
.nav *:has(.sub-menu-link-wrapper) {
  position: relative;
}
.nav *:has(.sub-menu-link-wrapper) > .sub-menu-link-wrapper {
  transition: all 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
.nav *:has(.sub-menu-link-wrapper):hover > .sub-menu-link-wrapper {
  display: flex;
  opacity: 1;
}
.nav .menu-wrapper,
.nav .burger-menu > * {
  pointer-events: none;
}
.nav .menu-wrapper > * {
  pointer-events: all;
}

.mobile-menu {
  position: fixed;
  width: 100vw;
  height: 0;
  overflow: hidden;
  z-index: 9;
  top: 0;
  background-color: white;
}
.mobile-menu * {
  transition: all 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
.mobile-menu .nav-wrapper {
  overflow-y: scroll;
  position: relative;
  top: 18svh;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 8rem;
}
.mobile-menu .nav-wrapper::-webkit-scrollbar {
  display: none;
}
@media (max-width: 575.98px) {
  .mobile-menu .nav-wrapper {
    top: 6rem;
    height: calc(100svh - 6rem);
  }
}
.mobile-menu .nav-link {
  width: 100%;
}
.mobile-menu .nav-link:not(:last-child) {
  border-bottom: 1px solid #dfdde1;
}
.mobile-menu .nav-link .menu-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #1b0d26;
  padding: 2.3rem 5vw;
}
.mobile-menu .nav-link .menu-text.menu-active {
  color: #00a87f;
}
@media (max-width: 1199.98px) {
  .mobile-menu .nav-link .menu-text {
    padding: 2.3rem 5vw;
    font-size: 2.3rem;
  }
}
@media (max-width: 991.98px) {
  .mobile-menu .nav-link .menu-text {
    padding: 2rem 5vw;
    font-size: 1.3rem;
  }
}
@media (max-width: 767.98px) {
  .mobile-menu .nav-link .menu-text {
    padding: 2rem calc(4vw + 1rem);
  }
}
.mobile-menu .nav-link .menu-text .children {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.mobile-menu .nav-link .menu-text .children svg {
  transform-origin: center;
  transition: all 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
.mobile-menu .nav-link a {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.mobile-menu .nav-link a.menu-active {
  color: #00a87f;
}
@media (max-width: 1199.98px) {
  .mobile-menu .nav-link a {
    font-size: 2rem;
    padding: 0.2rem 0;
  }
}
@media (max-width: 767.98px) {
  .mobile-menu .nav-link a {
    font-size: 1.1rem;
  }
}
.mobile-menu .nav-link .mobile-menu-content-wrapper {
  max-height: 0;
  overflow: hidden;
}
.mobile-menu .nav-link .mobile-menu-content-wrapper .mobile-menu-content {
  padding: 0 5vw;
}
@media (max-width: 1199.98px) {
  .mobile-menu .nav-link .mobile-menu-content-wrapper .mobile-menu-content {
    padding: 2.3rem 5vw;
    top: 1vh;
  }
}
@media (max-width: 991.98px) {
  .mobile-menu .nav-link .mobile-menu-content-wrapper .mobile-menu-content {
    padding: 2rem 5vw;
  }
}
@media (max-width: 767.98px) {
  .mobile-menu .nav-link .mobile-menu-content-wrapper .mobile-menu-content {
    padding: 0 calc(4vw + 1rem) 1.5rem calc(4vw + 1rem);
  }
}
.mobile-menu .nav-link .mobile-menu-content-wrapper .sub-menu-link-wrapper {
  position: relative;
  top: -1rem;
}
.mobile-menu .nav-link .mobile-menu-content-wrapper .sub-menu-link-wrapper li {
  padding: 1rem 0;
  font-weight: 600;
}
.mobile-menu .nav-link .mobile-menu-trigger:checked + label > span > svg {
  transform: rotate(-180deg);
  color: #00a87f;
}
.mobile-menu .nav-link .mobile-menu-trigger:checked + * + .mobile-menu-content-wrapper {
  max-height: 30rem;
}
.mobile-menu input[type=checkbox] {
  display: none;
}

a.buttons,
.buttons {
  transition: all 0.4s cubic-bezier(0, 0.25, 0.25, 1);
  transform-origin: "center center";
  border-color: transparent;
  display: flex;
  padding: 1rem 3rem;
  align-items: center;
  align-content: center;
  border-radius: 3rem;
  width: fit-content;
  text-align: center;
  text-wrap: nowrap;
  min-width: 6rem;
}
a.buttons:hover,
.buttons:hover {
  transform: scale(1.03);
  cursor: pointer;
}
@media (max-width: 991.98px) {
  a.buttons.change-to-icon,
  .buttons.change-to-icon {
    padding: 0;
    width: 5rem;
    height: 5rem;
    min-width: auto;
  }
  a.buttons.change-to-icon .label,
  .buttons.change-to-icon .label {
    display: none;
  }
  a.buttons.change-to-icon .icon,
  .buttons.change-to-icon .icon {
    display: flex !important;
  }
}
@media (max-width: 991.98px) {
  a.buttons.change-to-icon,
  .buttons.change-to-icon {
    padding: 0;
    width: 4rem;
    height: 4rem;
    min-width: auto;
  }
}
a.buttons.primary-button,
.buttons.primary-button {
  background-color: #210e39;
}
a.buttons.primary-button, a.buttons.primary-button > span,
.buttons.primary-button,
.buttons.primary-button > span {
  color: white;
}
a.buttons.primary-button:hover,
.buttons.primary-button:hover {
  background-color: #2d134e;
}
a.buttons.primary-button.outlined,
.buttons.primary-button.outlined {
  border: 2px solid #210e39;
  background-color: transparent;
}
a.buttons.primary-button.outlined, a.buttons.primary-button.outlined > span,
.buttons.primary-button.outlined,
.buttons.primary-button.outlined > span {
  color: #210e39;
}
a.buttons.primary-button.outlined:hover,
.buttons.primary-button.outlined:hover {
  background-color: #210e39;
}
a.buttons.primary-button.outlined:hover, a.buttons.primary-button.outlined:hover > span,
.buttons.primary-button.outlined:hover,
.buttons.primary-button.outlined:hover > span {
  color: white;
}
a.buttons.white-button,
.buttons.white-button {
  background-color: white;
}
a.buttons.white-button, a.buttons.white-button > span,
.buttons.white-button,
.buttons.white-button > span {
  color: #210e39;
}
a.buttons.white-button:hover,
.buttons.white-button:hover {
  background-color: #ecebed;
}
a.buttons.white-button:hover, a.buttons.white-button:hover > span,
.buttons.white-button:hover,
.buttons.white-button:hover > span {
  color: #210e39;
}
a.buttons.white-button.outlined,
.buttons.white-button.outlined {
  border: 2px solid white;
  background-color: transparent;
}
a.buttons.white-button.outlined, a.buttons.white-button.outlined > span,
.buttons.white-button.outlined,
.buttons.white-button.outlined > span {
  color: white;
}
a.buttons.white-button.outlined:hover,
.buttons.white-button.outlined:hover {
  background-color: white;
}
a.buttons.white-button.outlined:hover, a.buttons.white-button.outlined:hover > span,
.buttons.white-button.outlined:hover,
.buttons.white-button.outlined:hover > span {
  color: #210e39;
}

a.whatsapp-btn {
  position: fixed;
  width: 4rem;
  height: auto;
  bottom: 0;
  right: 0;
  z-index: 99;
}
a.whatsapp-btn svg {
  width: 100%;
  height: 100%;
}

.footer {
  width: 100vw;
  margin-top: 15rem;
  margin-bottom: 2vw;
  position: relative;
  height: 56rem;
  justify-content: center;
}
@media (max-width: 1199.98px) {
  .footer {
    height: fit-content;
  }
}
@media (max-width: 767.98px) {
  .footer {
    margin-bottom: 6rem;
  }
}
.footer #footer-mask-svg {
  position: absolute;
  z-index: -10;
}
.footer .footer-wrapper {
  width: 96%;
  height: 100%;
  background-color: #2d134e;
  position: relative;
}
@media (max-width: 1199.98px) {
  .footer .footer-wrapper {
    padding: 13rem 0 12rem 0;
  }
}
.footer .footer-wrapper .brand-pattern {
  top: 0;
  right: 0;
  position: absolute;
}
@media (max-width: 575.98px) {
  .footer .footer-wrapper .brand-pattern svg {
    width: 50vw;
  }
}
.footer .footer-wrapper .footer-links-wrapper {
  margin: auto;
  width: 80%;
  position: relative;
}
@media (max-width: 1199.98px) {
  .footer .footer-wrapper .footer-links-wrapper {
    width: 88%;
  }
}
@media (max-width: 991.98px) {
  .footer .footer-wrapper .footer-links-wrapper {
    width: 85%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-flow: wrap-reverse;
  }
}
@media (max-width: 575.98px) {
  .footer .footer-wrapper .footer-links-wrapper {
    margin-bottom: 2rem;
  }
}
.footer .footer-wrapper .footer-links-wrapper .footer-left-side {
  width: 40%;
}
@media (max-width: 991.98px) {
  .footer .footer-wrapper .footer-links-wrapper .footer-left-side {
    width: 100%;
    margin-top: 7rem;
    justify-content: space-between;
  }
  .footer .footer-wrapper .footer-links-wrapper .footer-left-side > .content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .footer .footer-wrapper .footer-links-wrapper .footer-left-side > .content-wrapper > div:last-child {
    display: flex;
    justify-content: space-between !important;
    flex-direction: row;
    gap: 2rem;
    margin-bottom: 4rem;
  }
}
@media (max-width: 991.98px) and (max-width: 575.98px) {
  .footer .footer-wrapper .footer-links-wrapper .footer-left-side > .content-wrapper > div:last-child {
    flex-direction: column;
    gap: 0rem;
  }
}
.footer .footer-wrapper .footer-links-wrapper .footer-left-side .contact-button {
  margin-top: 2rem;
}
.footer .footer-wrapper .footer-links-wrapper .footer-left-side .contact-button .title {
  gap: 1rem;
}
.footer .footer-wrapper .footer-links-wrapper .footer-left-side .contact-button h6.big {
  margin-left: 2.5rem;
  opacity: 0.6;
  font-size: 1.6rem;
  width: fit-content;
  transition: all 0.4s cubic-bezier(0, 0.25, 0.25, 1);
  /* @include media-breakpoint-down(md) {
  	font-size: 1.5vw;
  } */
}
.footer .footer-wrapper .footer-links-wrapper .footer-left-side .contact-button h6.big:hover {
  opacity: 1;
}
.footer .footer-wrapper .footer-links-wrapper .footer-left-side p {
  line-height: 1.6;
}
.footer .footer-wrapper .footer-links-wrapper .footer-left-side .footer-form-wrapper {
  gap: 2rem;
  margin-bottom: 2rem;
}
.footer .footer-wrapper .footer-links-wrapper .footer-left-side .footer-form-wrapper .buttons {
  width: fit-content;
}
.footer .footer-wrapper .footer-links-wrapper .footer-left-side .footer-form-wrapper p {
  width: 60%;
}
@media (max-width: 991.98px) {
  .footer .footer-wrapper .footer-links-wrapper .footer-left-side .footer-form-wrapper p {
    width: 80%;
  }
}
@media (max-width: 991.98px) {
  .footer .footer-wrapper .footer-links-wrapper .footer-right-side {
    width: 100%;
  }
}
.footer .footer-wrapper .footer-links-wrapper .footer-right-side__col {
  width: 100%;
  height: 100%;
}
.footer .footer-wrapper .footer-links-wrapper .footer-right-side .content-wrapper {
  gap: 8vw;
}
@media (max-width: 991.98px) {
  .footer .footer-wrapper .footer-links-wrapper .footer-right-side .content-wrapper {
    gap: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media (max-width: 575.98px) {
  .footer .footer-wrapper .footer-links-wrapper .footer-right-side .content-wrapper {
    flex-direction: column !important;
    gap: 1.5rem;
  }
}
.footer .footer-wrapper .footer-links-wrapper .footer-right-side ul {
  gap: 1.5rem;
}
.footer .footer-wrapper .footer-links-wrapper .footer-right-side ul li {
  list-style-type: none;
  white-space: nowrap;
}
@media (max-width: 575.98px) {
  .footer .footer-wrapper .footer-links-wrapper .footer-right-side ul li > * {
    font-size: 6.5vw !important;
  }
}
.footer .footer-wrapper .footer-links-wrapper .footer-right-side ul li a {
  opacity: 0.72;
  transition: opacity 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
.footer .footer-wrapper .footer-links-wrapper .footer-right-side ul li a:hover {
  opacity: 1;
}
.footer .footer-wrapper .social-wrapper {
  position: absolute;
  height: 15%;
  left: 0;
  bottom: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 2vw;
  width: 68vw;
}
@media (max-width: 1199.98px) {
  .footer .footer-wrapper .social-wrapper {
    height: fit-content;
    bottom: 5%;
    gap: 2rem;
    padding: 0 1vw 0 3vw;
    align-items: flex-end;
  }
}
@media (max-width: 991.98px) {
  .footer .footer-wrapper .social-wrapper {
    flex-direction: row !important;
    padding: 0 5vw;
    bottom: 2%;
    flex-flow: wrap-reverse;
    align-items: flex-start;
  }
}
@media (max-width: 575.98px) {
  .footer .footer-wrapper .social-wrapper {
    width: 100%;
  }
}
@media (max-width: 1199.98px) {
  .footer .footer-wrapper .social-wrapper-items {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .footer .footer-wrapper .social-wrapper-items {
    justify-content: flex-start;
  }
}
.footer .footer-wrapper .social-wrapper .dahi-copyright {
  opacity: 0.72;
  white-space: nowrap;
}
.footer .footer-wrapper .social-wrapper ul {
  gap: 1rem;
  justify-content: center;
}
@media (max-width: 1199.98px) {
  .footer .footer-wrapper .social-wrapper ul {
    flex-wrap: wrap;
    width: 85%;
    display: flex;
    justify-content: flex-end;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .footer .footer-wrapper .social-wrapper ul {
    width: 75%;
    justify-content: flex-start;
  }
}
@media (max-width: 767.98px) {
  .footer .footer-wrapper .social-wrapper ul {
    width: 100%;
    height: fit-content;
  }
}
@media (max-width: 575.98px) {
  .footer .footer-wrapper .social-wrapper ul {
    justify-content: flex-start;
  }
}
.footer .footer-wrapper .social-wrapper ul li {
  list-style-type: none;
  white-space: nowrap;
  display: flex;
  justify-content: center;
}
.footer .footer-wrapper .social-wrapper ul li a {
  transition: opacity 0.4s cubic-bezier(0, 0.25, 0.25, 1);
  opacity: 0.72;
}
.footer .footer-wrapper .social-wrapper ul li a:hover {
  opacity: 1;
}
.footer .made-by-wlitz {
  position: absolute;
  bottom: 0;
  right: 2vw;
  width: 25%;
  justify-content: center;
  height: 15%;
  align-items: center;
}
@media (max-width: 991.98px) {
  .footer .made-by-wlitz {
    box-sizing: unset !important;
    height: fit-content;
    padding: 3rem 1rem;
  }
}
@media (max-width: 767.98px) {
  .footer .made-by-wlitz {
    right: 0;
    padding: 0;
    bottom: -3rem;
    width: 100%;
  }
}

ul.bullet,
ol.bullet {
  line-height: 1.5;
  position: relative;
  margin-top: 3rem;
  width: 100%;
}
ul.bullet li,
ol.bullet li {
  padding: 0.5rem 0rem;
  position: relative;
}
ul.bullet li.add-margin,
ol.bullet li.add-margin {
  margin-top: 6rem;
}
ul.bullet li .full-width,
ol.bullet li .full-width {
  position: relative;
  margin-left: -2rem;
  width: calc(100% + 2rem);
  max-width: unset;
}
@media (max-width: 1199.98px) {
  ul.bullet li .full-width,
  ol.bullet li .full-width {
    width: calc(100% + 2rem);
    margin-left: -2rem;
  }
}

ol.bullet li {
  margin-left: 2rem;
  margin-top: 2rem;
}

ul.bullet {
  list-style-type: square;
  padding-left: 1.5rem;
}
ul.bullet.bullet-green li::marker {
  margin-left: 1rem;
  color: #00a87f;
}
ul.bullet.bullet-ink li::marker {
  color: #00a87f;
}
ul.bullet.bullet-purple li::marker {
  color: #210e39;
}
ul.bullet.square li::before {
  margin-right: 1.2rem;
}

ul.grid {
  columns: 2;
  column-gap: 3rem;
  column-fill: balance;
}

.link-box {
  background-color: #f7f5f8;
  border-radius: 2rem;
  height: 10rem;
  justify-content: space-between;
  padding: 0 2rem 0 1rem;
  align-items: center;
  transition: background-color 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
@media (max-width: 575.98px) {
  .link-box {
    flex-direction: column !important;
    height: auto;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 2rem 2rem 2.5rem 2rem;
  }
}
.link-box.no-left-icon {
  padding: 0 3rem;
}
@media (max-width: 767.98px) {
  .link-box.no-left-icon {
    padding: 0;
  }
}
.link-box > div {
  height: 100%;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767.98px) {
  .link-box .content-wrapper {
    flex-direction: column !important;
    width: 100%;
  }
}
.link-box .text-wrapper {
  height: 100%;
  justify-content: center;
  flex-shrink: 0;
}
@media (max-width: 767.98px) {
  .link-box .text-wrapper {
    align-items: center;
    gap: 0.5rem;
  }
  .link-box .text-wrapper > * {
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .link-box a {
    margin-top: 2rem;
  }
}

a.link-box:hover {
  background-color: #ecebed;
}

.accordion-list .accordion-item {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  border-bottom: 1px solid #dfdde1;
  transition: all 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
.accordion-list .accordion-item .accordion-content-wrapper {
  height: 0;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 0px;
  max-height: 0px;
  transition: all 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
.accordion-list .accordion-item .accordion-title {
  letter-spacing: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 6rem;
  text-transform: none;
  color: #1b0d26;
  transition: all 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
.accordion-list .accordion-item .accordion-title:hover {
  cursor: pointer;
  color: #00a87f;
}
.accordion-list .accordion-item .accordion-title::after {
  content: "+";
  right: 0;
  margin-left: 2rem;
  display: inline-block;
  position: relative;
  transition: all 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
.accordion-list .accordion-item .accordion-trigger:checked + .accordion-title::after {
  transform: rotate(-45deg);
}
.accordion-list .accordion-item .accordion-trigger:checked + .accordion-title {
  color: #00a87f;
}
.accordion-list .accordion-item .accordion-trigger:checked + * + .accordion-content-wrapper {
  height: 100%;
  max-height: none;
  padding: 1rem 0 2rem 0;
}
.accordion-list .accordion-item input[type=checkbox] {
  display: none;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.overlay.purple {
  background-color: #2d134e;
  opacity: 0.85;
}

div:has(.overlay) {
  position: relative;
}

.custom-video-player-wrapper {
  overflow: hidden;
  border-radius: 4rem;
  justify-content: center;
}
.custom-video-player-wrapper:hover {
  cursor: pointer;
}
.custom-video-player-wrapper .overlay-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.custom-video-player-wrapper.full-width {
  width: 100%;
  height: 100svh;
}
@media (max-width: 1199.98px) {
  .custom-video-player-wrapper.full-width {
    height: 80svh;
    border-radius: 3rem;
  }
}
@media (max-width: 991.98px) {
  .custom-video-player-wrapper.full-width {
    height: 40svh;
  }
}
@media (max-width: 575.98px) {
  .custom-video-player-wrapper.full-width {
    height: 70svh;
  }
}
.custom-video-player-wrapper .play-button {
  position: absolute;
  z-index: 2;
  transition: transform 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .custom-video-player-wrapper .play-button {
    transform: scale(0.7);
    top: 25%;
  }
}
@media (max-width: 575.98px) {
  .custom-video-player-wrapper .play-button {
    top: 20%;
    transform: scale(0.6);
  }
}
.custom-video-player-wrapper .play-button:hover {
  transform: scale(1.1);
}
@media (max-width: 767.98px) {
  .custom-video-player-wrapper .play-button:hover {
    transform: scale(0.8);
  }
}
.custom-video-player-wrapper .text-wrapper {
  gap: 1rem;
  position: absolute;
  z-index: 2;
  width: 40%;
  bottom: 5rem;
  left: 5rem;
}
@media (max-width: 1199.98px) {
  .custom-video-player-wrapper .text-wrapper {
    width: 80%;
    bottom: 3rem;
    left: 3rem;
  }
}
@media (max-width: 767.98px) {
  .custom-video-player-wrapper .text-wrapper {
    width: 100%;
    padding: 2rem;
    bottom: 0;
    left: 0;
  }
  .custom-video-player-wrapper .text-wrapper h6:not(.overline) {
    font-size: 1.2rem;
  }
}
.custom-video-player-wrapper .product-fullscreen-video {
  justify-content: center;
  width: 100vw;
  height: 100svh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  overflow: hidden;
}
.custom-video-player-wrapper .product-fullscreen-video .play-button {
  opacity: 0;
  z-index: 10;
}
.custom-video-player-wrapper .product-fullscreen-video .video-time-wrapper {
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
}
.custom-video-player-wrapper .product-fullscreen-video .video-time-wrapper span {
  position: absolute;
  bottom: 2rem;
  left: 0;
}
.custom-video-player-wrapper .product-fullscreen-video .video-time-wrapper .video-overlay {
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 20.98%);
}

.card {
  position: relative;
  margin-top: 4rem;
}
.card.reference .tag {
  position: absolute;
  z-index: 2;
  top: 2rem;
  left: 2rem;
  padding: 0.5rem 1rem;
  border-radius: 3rem;
  background-color: white;
}
.card.news {
  margin-top: 6rem;
}
.card.news.big .text-wrapper {
  position: absolute;
  padding: 3rem;
  bottom: 0;
  left: 0;
  width: 50%;
}
.card .text-wrapper {
  gap: 1rem;
}
.card.values {
  background-color: #f7f5f8;
  justify-content: center;
  border-radius: 1.5rem;
  aspect-ratio: 4/3;
  height: auto;
  padding: 2rem;
  gap: 1rem;
  margin-top: 0;
}
@media (max-width: 1199.98px) {
  .card.management .img-wrapper {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }
}

a.card img {
  transition: all 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
a.card:hover img {
  transform: scale(1.05);
  opacity: 0.8;
}

.card.certificate .content-wrapper {
  background-color: #f7f5f8;
  border-radius: 2rem;
  padding: 0;
}
.card.certificate .img-wrapper {
  background-color: transparent;
  height: auto;
  width: 33%;
  margin: 0;
  aspect-ratio: unset;
}
.card.certificate .img-wrapper img {
  width: 100%;
  height: auto;
}
.card.certificate .text-wrapper {
  gap: 0.5rem;
  width: 50%;
}
.card.certificate a {
  margin-top: 1rem;
}

.input-wrapper input {
  display: flex;
  padding: 1.25rem 1.5rem;
  align-items: center;
  border-radius: 1rem;
  border: 2px solid transparent;
  transition: border 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
.input-wrapper input:focus {
  border: 2px solid #dfdde1;
}
.input-wrapper input::placeholder {
  font-size: clamp(1rem, 0.466vw + 0.806rem, 1rem);
  color: #a7a2ac;
  font-weight: 200;
  width: 100%;
}
.input-wrapper input:disabled {
  background-color: #dfdde1;
}
.input-wrapper .helper {
  display: none;
  margin-top: -0.5rem;
  transition: display 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
.input-wrapper .helper.active {
  display: block;
}

.just-validate-error-field {
  border: 2px solid #d93a43 !important;
  transition: all 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}

.just-validate-error-label {
  transition: all 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}

form {
  flex-wrap: wrap;
  row-gap: 2rem;
}

.input-wrapper select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M16.263 8L7.73703 8C6.13964 8 5.18686 9.78029 6.07293 11.1094L10.3359 17.5038C11.1275 18.6913 12.8725 18.6913 13.6641 17.5039L17.9271 11.1094C18.8131 9.78029 17.8604 8 16.263 8Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center, 50%, 50%;
  background-size: 1rem;
  background-position: calc(100% - 1rem);
  background-color: white;
  appearance: none;
  padding: 1.25rem 1.5rem;
  border-radius: 1rem;
  border: 2px solid transparent;
  font-size: clamp(1rem, 0.466vw + 0.806rem, 1rem);
  color: #1b0d26;
  font-weight: 200;
  transition: border 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
.input-wrapper select:focus {
  border: 2px solid #dfdde1;
}
.input-wrapper select:invalid {
  color: #a7a2ac;
}
.input-wrapper selectselect:hover {
  cursor: pointer;
}
.input-wrapper .helper {
  display: none;
  margin-top: -0.5rem;
  transition: display 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
.input-wrapper .helper.active {
  display: block;
}

.input-wrapper textarea {
  display: flex;
  padding: 1.25rem 1.5rem;
  height: 14rem;
  align-items: center;
  border-radius: 1rem;
  border: 2px solid transparent;
  background-color: white;
  appearance: none;
  resize: none;
  transition: border 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
.input-wrapper textarea:focus {
  border: 2px solid #dfdde1;
}
.input-wrapper textarea::placeholder {
  font-size: clamp(1rem, 0.466vw + 0.806rem, 1rem);
  color: #a7a2ac;
  font-weight: 200;
  width: 100%;
}
.input-wrapper textarea:invalid {
  border: 1px solid #b31d29;
}
.input-wrapper .helper {
  margin-top: -0.5rem;
  transition: display 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
.input-wrapper .helper.active {
  display: block;
}

.text-link {
  transition: color 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
.text-link:hover {
  color: #00a87f;
}

.transition-section {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100vw;
  height: 100svh;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: #210e39;
}
.transition-section svg {
  position: absolute;
  width: 20vw;
  min-width: 8rem;
}

.lightbox {
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
  z-index: 998;
}
.lightbox.hide {
  display: none;
}
.lightbox__close {
  top: 1rem;
  right: 1rem;
}
.lightbox__prev, .lightbox__next {
  top: 50%;
}
.lightbox__prev, .lightbox__next, .lightbox__close {
  transition: all 0.4s cubic-bezier(0, 0.25, 0.25, 1);
  position: absolute;
  z-index: 2;
}
.lightbox__prev:hover, .lightbox__next:hover, .lightbox__close:hover {
  color: #24e0b1;
  cursor: pointer;
}
.lightbox__prev {
  left: 2rem;
}
.lightbox__next {
  right: 2rem;
}
.lightbox__numb {
  position: absolute;
  bottom: 4vh;
}
.lightbox__inner {
  z-index: -1;
  overflow: hidden;
  color: white;
  border-radius: 1rem;
  position: absolute;
  justify-content: center;
  max-width: 80vw;
  max-height: 80vh;
}
.lightbox__inner img {
  position: relative;
  width: 100%;
  height: auto;
}

wave-audio-path-player {
  border-radius: 2rem;
  background: rgba(36, 224, 177, 0.1);
  padding: 3rem;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
wave-audio-path-player .slider {
  flex-shrink: 1;
}

.blob-text {
  transform: translate(50px, 120px);
  font-size: 2.7rem;
}

.checkbox-wrapper .checkbox {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  user-select: none;
  text-transform: unset;
  letter-spacing: 0;
  cursor: pointer;
}
.checkbox-wrapper .checkbox:hover > .checkmark {
  border: 1px solid #a8a1ad;
}
.checkbox-wrapper .checkbox .checkmark {
  transition: all 0.4s cubic-bezier(0, 0.25, 0.25, 1);
  border: 1px solid #dfdde1;
  border-radius: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: white;
  color: #2d134e;
}
.checkbox-wrapper .checkbox .checkmark .icon {
  position: absolute;
  z-index: 2;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
.checkbox-wrapper .checkbox-trigger {
  display: none;
}
.checkbox-wrapper .checkbox-trigger:checked + .checkbox > .checkmark {
  background-color: #2d134e;
  border: 1px solid transparent;
}
.checkbox-wrapper .checkbox-trigger:checked + .checkbox > .checkmark > .icon {
  opacity: 1;
}

label:invalid {
  color: #b31d29;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100svw;
  height: 100svh;
}
.modal__overlay {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100svw;
  height: 100svh;
  background-color: #210e39;
  opacity: 0.8;
}
.modal__brand-svg {
  position: absolute;
  left: 0;
  top: 0;
}
.modal__close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.modal__inner {
  position: relative;
  z-index: 1000;
  width: 72%;
  height: auto;
  background-color: white;
  border-radius: 1rem;
  padding: 10rem 8rem 6rem 8rem;
}
.modal__inner > * {
  text-align: center;
}
.modal__inner h6 {
  margin-top: 4rem;
  margin-bottom: 1rem;
}
.modal__inner .buttons {
  margin-top: 3rem;
}

.img-wrapper {
  /* inside layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  /* inside appearance */
  overflow: hidden;
  /* self appearance */
  border-radius: 2rem;
  background-color: #24e0b1;
}
.img-wrapper img {
  width: 100%;
  height: auto;
}
.img-wrapper.no-aspect {
  aspect-ratio: unset;
}
.img-wrapper.no-aspect img {
  aspect-ratio: unset;
}
.img-wrapper.aspect-2_1-2 {
  aspect-ratio: 2/1.2;
}
.img-wrapper.aspect-2_1-2 img {
  aspect-ratio: 2/1.2;
}
.img-wrapper.aspect-3_4 {
  aspect-ratio: 3/4;
}
.img-wrapper.aspect-3_4 img {
  aspect-ratio: 3/4;
}

.reference-filter {
  height: 10rem;
  padding: 0 4rem;
  background-color: #f7f5f8;
  border-radius: 2rem;
  gap: 8rem;
  justify-content: space-between;
}
.reference-filter ul {
  list-style: none;
  gap: 4rem;
  flex-shrink: 1;
  height: 100%;
  justify-content: space-around;
}
.reference-filter ul li {
  display: flex;
  align-content: center;
  align-items: center;
  height: 100%;
  justify-content: center;
  transition: all 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
.reference-filter ul li:hover {
  cursor: pointer;
  color: #00a87f;
}
.reference-filter ul li.active {
  font-weight: 600;
  color: #00a87f;
  border-bottom: 2px solid #00a87f;
}

.mainpage-section-title {
  display: flex;
  padding: 8.5rem 0rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 6.5rem;
  overflow-x: hidden;
}
@media (max-width: 767.98px) {
  .mainpage-section-title {
    padding: 4rem 0rem;
    gap: 2.5rem;
  }
}
.mainpage-section-title .title-wrapper {
  gap: 12vw;
  width: 100%;
}
@media (max-width: 767.98px) {
  .mainpage-section-title .title-wrapper h4 {
    font-size: 20vw;
  }
}
@media (max-width: 767.98px) {
  .mainpage-section-title__desc {
    width: 87vw;
  }
}
@media (max-width: 767.98px) {
  .mainpage-section-title__desc h4 {
    font-size: 7vw;
  }
}
.mainpage-section-title__button-wrapper {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
@media (max-width: 991.98px) {
  .mainpage-section-title__button-wrapper {
    margin: 1rem 0 3rem 0;
    justify-content: flex-start;
  }
}

/* ABOUT SECTION  */
.section-mainpage-about {
  height: 100vh;
}
.section-mainpage-about .container,
.section-mainpage-about .content-wrapper {
  height: 100%;
}
.section-mainpage-about .content-wrapper {
  width: 80%;
}
@media (max-width: 767.98px) {
  .section-mainpage-about .content-wrapper {
    width: 90%;
  }
}
@media (max-width: 1199.98px) {
  .section-mainpage-about .content-wrapper h4 {
    font-size: 5vw;
  }
}
@media (max-width: 991.98px) {
  .section-mainpage-about .content-wrapper h4 {
    font-size: 7vw;
  }
}
@media (max-width: 767.98px) {
  .section-mainpage-about .content-wrapper h4 {
    font-size: 9vw;
  }
}

/* SLOGAN SECTION  */
.section-slogan {
  height: 100svh;
}
@media (max-width: 767.98px) {
  .section-slogan {
    height: 60svh;
  }
}
.section-slogan .container,
.section-slogan .content-wrapper {
  height: 100%;
  width: 100%;
}
.section-slogan .content-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

/* PRODUCT SECTION  */
.section-product {
  overflow: hidden;
}

.product-list {
  position: relative;
  height: 100svh;
  width: fit-content;
  background-color: #11091B;
}
.product-list::-webkit-scrollbar {
  display: none;
}
@media (max-width: 1199.98px) {
  .product-list {
    background-color: white;
    width: 100%;
    height: auto;
    overflow-y: auto;
  }
}
.product-item-wrapper {
  position: relative;
  overflow: hidden;
  min-width: 85vw;
  height: 100svh;
  background-color: #00a87f;
}
@media (max-width: 1199.98px) {
  .product-item-wrapper {
    height: 80svh;
  }
}
@media (max-width: 991.98px) {
  .product-item-wrapper {
    height: 70svh;
  }
}
@media (max-width: 767.98px) {
  .product-item-wrapper {
    height: 50svh;
  }
}
.product-item-wrapper img {
  z-index: 0;
  position: absolute;
  height: 100%;
  width: auto;
  object-fit: cover;
  min-width: 100%;
  transition: opacity 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
.product-item-wrapper:hover > img {
  opacity: 0.85;
}
.product-img-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 43.75%, rgb(0, 0, 0) 100%);
}
.product-detail {
  position: absolute;
  z-index: 2;
  gap: 1rem;
  width: 50%;
  height: fit-content;
  left: 0;
  bottom: 0;
  margin: 5vw;
}
@media (max-width: 1199.98px) {
  .product-detail {
    width: auto;
  }
}
.product-detail h5 {
  margin-top: 1.5rem;
  display: none;
}
.product-detail h6 {
  margin-top: 2rem;
  opacity: 0.8;
}
.product-more-wrapper {
  height: 100%;
  width: 25vw;
  justify-content: center;
  position: relative;
  color: #210e39;
  background-color: white;
  transition: color 0.2s cubic-bezier(0, 0.25, 0.25, 1);
  transition: background-color 0.2s cubic-bezier(0, 0.25, 0.25, 1);
}
@media (max-width: 1199.98px) {
  .product-more-wrapper {
    height: auto;
    width: auto;
    border-top: 1px solid #ecebed;
  }
}
.product-more-wrapper .more-button {
  position: relative;
  width: fit-content;
  height: fit-content;
}
@media (max-width: 1199.98px) {
  .product-more-wrapper .more-button {
    width: 50vw;
  }
}
@media (max-width: 767.98px) {
  .product-more-wrapper .more-button {
    width: 70vw;
  }
}
.product-more-wrapper .more-detail-text {
  gap: 1rem;
  justify-content: center;
}
.product-more-wrapper:hover {
  background-color: #1b0d26;
  color: white !important;
}

/* SERVICE SECTION  */
.section-service {
  background-color: #2d134e;
  padding-bottom: 30vh;
  border-radius: 0 0 2rem 2rem;
}
@media (max-width: 1199.98px) {
  .section-service {
    padding-bottom: 20svh;
    padding-top: 10svh;
  }
}
.section-service .intro-box {
  gap: 2rem;
}
.section-service .intro-box .text-wrapper {
  gap: 1rem;
}
.section-service .video-player {
  margin: auto;
  position: relative;
  width: 96vw;
  height: 100vh;
  border-radius: 5rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
@media (max-width: 1199.98px) {
  .section-service .video-player {
    border-radius: 2rem;
    height: 80svh;
  }
}
@media (max-width: 991.98px) {
  .section-service .video-player {
    border-radius: 2rem;
    height: 50svh;
  }
}
.section-service .video-player img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: auto;
  min-height: 100%;
}
.section-service .flex-grid {
  margin-top: 10rem;
  row-gap: 7rem;
}

/* REFERENCES SECTION  */
.section-count {
  overflow: hidden;
  position: relative;
  padding: 40svh 0;
}
@media (max-width: 1199.98px) {
  .section-count {
    padding: 25svh 0;
  }
}

/* NEWS SECTION  */
.section-news {
  background-color: #2e164d;
  color: white;
  border-radius: 0 0 2rem 2rem;
  position: relative;
  z-index: 1;
  padding-bottom: 30vh;
  margin-bottom: 10vh;
  width: 100%;
}
@media (max-width: 1199.98px) {
  .section-news {
    padding-bottom: 20vh;
  }
}
@media (max-width: 991.98px) {
  .section-news {
    padding-bottom: 10vh;
  }
}
.section-news .top-bg {
  width: 100%;
  height: 10rem;
  top: -8rem;
  display: inline-block;
  position: absolute;
  z-index: -1;
}
.section-news .news-list-wrapper {
  display: grid;
  grid-template: 1fr 1fr 1fr 1fr 1fr/1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 3rem;
  height: 90vh;
}
@media (max-width: 1199.98px) {
  .section-news .news-list-wrapper {
    height: auto;
    grid-template: 1fr 1fr 1fr 1fr/1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 991.98px) {
  .section-news .news-list-wrapper {
    height: auto;
    gap: 1rem;
    grid-template: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr/1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 575.98px) {
  .section-news .news-list-wrapper {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
.section-news .news-list-wrapper .news-item {
  position: relative;
  border-radius: 2rem;
  overflow: hidden;
  color: #210e39;
}
@media (max-width: 1199.98px) {
  .section-news .news-list-wrapper .news-item {
    border-radius: 1.5rem;
    height: 100%;
  }
}
@media (max-width: 575.98px) {
  .section-news .news-list-wrapper .news-item {
    height: auto;
    width: 100%;
  }
}
.section-news .news-list-wrapper .news-item:hover > .news-text-wrapper {
  transform: translateY(-0.4rem);
  color: #2d134e;
}
.section-news .news-list-wrapper .news-item .news-text-wrapper {
  position: absolute;
  padding: 2.5rem;
  gap: 1rem;
  left: 0;
  bottom: 0;
  width: 100%;
  height: fit-content;
  transition: all 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
.section-news .news-list-wrapper .news-item .news-text-wrapper h4 {
  line-height: 1.2;
  margin-top: 1rem;
}
@media (max-width: 991.98px) {
  .section-news .news-list-wrapper .news-item .news-text-wrapper h4 {
    margin-bottom: 0;
  }
}
@media (max-width: 991.98px) {
  .section-news .news-list-wrapper .news-item .news-text-wrapper h6:not(.overline) {
    font-size: 1.5rem;
  }
}
@media (max-width: 575.98px) {
  .section-news .news-list-wrapper .news-item .news-text-wrapper {
    position: relative;
  }
}
.section-news .news-list-wrapper .news-item img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: auto;
  min-height: 100%;
  z-index: -2;
}
.section-news .news-list-wrapper .news-item .news-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 43.75%, rgb(255, 255, 255) 100%);
}
.section-news .news-list-wrapper .news-item.big {
  grid-row: 1/6;
  grid-column: 1/5;
}
@media (max-width: 1199.98px) {
  .section-news .news-list-wrapper .news-item.big {
    grid-row: 1/3;
    grid-column: 1/5;
  }
}
@media (max-width: 991.98px) {
  .section-news .news-list-wrapper .news-item.big {
    grid-row: 1/5;
    grid-column: 1/5;
  }
}
@media (max-width: 575.98px) {
  .section-news .news-list-wrapper .news-item.big {
    height: 40svh;
  }
}
.section-news .news-list-wrapper .news-item.big .news-text-wrapper {
  position: absolute;
}
@media (max-width: 1199.98px) {
  .section-news .news-list-wrapper .news-item.big .news-text-wrapper {
    width: 70%;
  }
}
@media (max-width: 767.98px) {
  .section-news .news-list-wrapper .news-item.big .news-text-wrapper {
    width: 100%;
  }
}
.section-news .news-list-wrapper .news-item.wide {
  background-color: #ecf9ff;
  grid-row: 1/4;
  grid-column: 5/9;
}
@media (max-width: 1199.98px) {
  .section-news .news-list-wrapper .news-item.wide {
    grid-row: 3/5;
    grid-column: 1/3;
  }
}
@media (max-width: 991.98px) {
  .section-news .news-list-wrapper .news-item.wide {
    grid-row: 5/7;
    grid-column: 1/5;
  }
}
.section-news .news-list-wrapper .news-item.box-1 {
  background-color: #fffddf;
  grid-row: 4/6;
  grid-column: 5/7;
}
@media (max-width: 1199.98px) {
  .section-news .news-list-wrapper .news-item.box-1 {
    grid-row: 3/3;
    grid-column: 3/5;
  }
}
@media (max-width: 991.98px) {
  .section-news .news-list-wrapper .news-item.box-1 {
    grid-row: 7/9;
    grid-column: 1/3;
  }
}
.section-news .news-list-wrapper .news-item.box-2 {
  background-color: #fdf4ff;
  grid-row: 4/6;
  grid-column: 7/9;
}
@media (max-width: 1199.98px) {
  .section-news .news-list-wrapper .news-item.box-2 {
    grid-row: 4/5;
    grid-column: 3/5;
  }
}
@media (max-width: 991.98px) {
  .section-news .news-list-wrapper .news-item.box-2 {
    grid-row: 7/9;
    grid-column: 3/5;
  }
}
@media (max-width: 1399.98px) {
  .section-news .news-list-wrapper .news-item.box-1 h6:not(.overline), .section-news .news-list-wrapper .news-item.box-2 h6:not(.overline) {
    font-size: 1.7rem;
  }
}
.section-news .news-list-wrapper .news-item.wide, .section-news .news-list-wrapper .news-item.box-1, .section-news .news-list-wrapper .news-item.box-2 {
  align-items: flex-end;
}
.section-news .news-list-wrapper .news-item.wide .news-text-wrapper, .section-news .news-list-wrapper .news-item.box-1 .news-text-wrapper, .section-news .news-list-wrapper .news-item.box-2 .news-text-wrapper {
  position: relative;
}

/* WHY SECTION  */
.section-why {
  position: relative;
  padding: 30vh 0 0 0;
  color: #210e39;
}
@media (max-width: 1199.98px) {
  .section-why {
    padding: 15vh 0 0 0;
  }
}
@media (max-width: 991.98px) {
  .section-why {
    padding: 10vh 0 0 0;
  }
}
.section-why .why-list-image {
  flex-wrap: wrap;
  gap: 3rem;
}
.section-why .why-list-image::-webkit-scrollbar {
  display: none;
}
@media (max-width: 1199.98px) {
  .section-why .why-list-image {
    position: relative;
    padding: 0 3.5vw;
    left: calc(-2vw - 1.5rem);
    margin-top: 4rem;
    gap: 1.2rem;
    display: flex;
    flex-direction: row;
    overflow: scroll;
    width: 100vw;
    flex-wrap: nowrap;
  }
}
@media (max-width: 575.98px) {
  .section-why .why-list-image {
    left: calc(-2vw - 1rem);
  }
}
.section-why .text-wrapper {
  width: 80%;
  gap: 1rem;
}
@media (max-width: 991.98px) {
  .section-why .text-wrapper {
    width: 100%;
  }
}
.section-why .text-wrapper .buttons {
  margin-top: 2rem;
}
.section-why .text-wrapper h2 {
  line-height: 1.2;
}
.section-why .text-wrapper p {
  line-height: 1.5;
}
.section-why .sticky-content {
  height: 100vh;
  justify-content: center;
}
@media (max-width: 1199.98px) {
  .section-why .sticky-content {
    height: auto;
  }
}
.section-why .why-list-item {
  overflow: hidden;
  border-radius: 2rem;
  justify-content: center;
  position: relative;
}
@media (max-width: 1199.98px) {
  .section-why .why-list-item {
    min-width: 55svw;
  }
}
@media (max-width: 991.98px) {
  .section-why .why-list-item {
    min-width: 80svw;
    border-radius: 1.5rem;
  }
}
.section-why .why-list-item h4 {
  position: absolute;
  margin: 3rem;
  padding: 0;
  line-height: 1.2;
  top: 0;
  left: 0;
}
@media (max-width: 991.98px) {
  .section-why .why-list-item h4 {
    margin: 2rem;
    font-size: 5vw;
  }
}
.section-why .why-list-item img {
  width: 100%;
  height: auto;
}

.section-patners {
  position: relative;
}
.section-patners h6 {
  width: 80%;
  text-align: center;
}
.section-patners .patners-wrapper {
  position: relative;
  margin: 8rem 0;
  justify-content: space-between;
}
.section-patners .patners-wrapper img {
  height: 6.5rem;
  width: auto;
}
.section-patners .container {
  justify-content: center;
}

.subpage-margin {
  margin: 10rem 0 0 0;
}
@media (max-width: 1199.98px) {
  .subpage-margin {
    margin: 6rem 0 0 0;
  }
}
.subpage-title.product svg {
  margin-bottom: 2rem;
}
.subpage-content-margin-xsmall {
  margin-top: 1rem;
}
.subpage-content-margin-small {
  margin-top: 2rem;
}
.subpage-content-margin-mid {
  margin-top: 3rem;
}
.subpage-content-margin-large {
  margin-top: 5rem;
}
.subpage-content-margin-xlarge {
  margin-top: 18rem;
}
@media (max-width: 1199.98px) {
  .subpage-content-margin-xlarge {
    margin-top: 9rem;
  }
}
.subpage-product-content-image {
  margin: 4rem 0;
  overflow: hidden;
  border-radius: 2rem;
}
.subpage-product-content-image img {
  width: 100%;
  height: auto;
}
.subpage-product-content-image.square, .subpage-product-content-image .square {
  aspect-ratio: 875/720;
}
.subpage-product-content-image.landscape, .subpage-product-content-image .landscape {
  aspect-ratio: 1750/1064;
}
@media (max-width: 1199.98px) {
  .subpage-product-content-image {
    margin: 3rem 0;
  }
}
.subpage-product-content-image.small-margin {
  margin: 3rem 0;
}
.subpage-product-content-image.xSmall-margin {
  margin: 2rem 0;
}

.sub-title-margin {
  margin-bottom: 2rem;
}

.hr-wrapper {
  background-color: #f7f5f8;
  border-radius: 3rem;
}
.hr-wrapper .hr-container {
  padding: 12rem 0;
}

.contact-map-wrapper {
  border-radius: 2rem;
  overflow: hidden;
  position: relative;
  min-width: 100%;
  height: 70vh;
  min-height: 100%;
  max-height: 800px;
}
.contact-map-wrapper .content-wrapper {
  position: absolute;
  padding: 3rem;
  left: 0;
  bottom: 0;
  z-index: 2;
}
.contact-adress-wrapper {
  margin: 0 3rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.contact-adress-wrapper .address-line-wrapper {
  padding: 2rem 0 2rem 0;
  border-bottom: 1px solid #dfdde1;
}
.contact-adress-wrapper .address-line-wrapper:last-child {
  border-bottom: 0;
}
.contact-job-form {
  padding: 10rem 0;
  background-color: #f7f5f8;
  border-radius: 3rem;
}

clipPath {
  display: flex;
}

.landing-product {
  margin: 9rem 0 0rem 0;
  /*  .landing-product-item-mask-2{
       @include md {
           transform: translate(-10px,0px);
       }
   }

   .landing-product-item-mask-1{
       @include md {
           transform: translate(0px,-20px);
       }
   } */
}
@media (max-width: 767.98px) {
  .landing-product {
    margin: 4rem 0 0rem 0;
  }
}
.landing-product .landing-product-list-wrapper {
  width: 100%;
}
.landing-product .landing-product-list-wrapper__column {
  gap: 18rem;
}
.landing-product .landing-product-list-wrapper .landing-product-items {
  gap: 9rem;
}
.landing-product .landing-product-list-wrapper .landing-product-item {
  box-sizing: border-box;
  position: relative;
  justify-content: center;
}
.landing-product .landing-product-list-wrapper .landing-product-item-wrapper {
  justify-content: center;
}
.landing-product .landing-product-list-wrapper .landing-product-item img {
  width: 100%;
  height: auto;
  position: relative;
}
.landing-product .landing-product-list-wrapper .landing-product-item .landing-product-img-wrapper {
  width: 100%;
  height: auto;
  border-radius: 2rem 0 2rem 2rem;
  overflow: hidden;
}
.landing-product .landing-product-list-wrapper .landing-product-item .landing-product-img-wrapper.reference-item {
  box-sizing: border-box;
  background-color: #f7f5f8;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: left;
  padding: 5rem 3rem 2rem 2.5rem;
}
.landing-product .landing-product-list-wrapper .landing-product-item .landing-product-img-wrapper.reference-item .reference-item__text-wrapper {
  margin-top: 8rem;
  gap: 0.5rem;
  width: 80%;
}
.landing-product .landing-product-list-wrapper .landing-product-item .landing-product-img-wrapper.reference-item .buttons {
  position: relative;
  margin-top: 3rem;
  padding: 1rem 2rem;
}
.landing-product .landing-product-list-wrapper .landing-product-item .landing-product-item-text-wrapper {
  margin: 2rem 0;
  width: 50%;
}
@media (max-width: 1399.98px) {
  .landing-product .landing-product-list-wrapper .landing-product-item .landing-product-item-text-wrapper {
    width: 80%;
  }
}
@media (max-width: 575.98px) {
  .landing-product .landing-product-list-wrapper .landing-product-item .landing-product-item-text-wrapper {
    width: 100%;
  }
}
.landing-product .landing-product-list-wrapper .landing-product-item .landing-product-item-text-wrapper .overline {
  margin-bottom: 1rem;
}

.gallery-thumb {
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  column-gap: 3rem;
  aspect-ratio: 1;
}
.gallery-thumb img {
  transition: all 0.4s cubic-bezier(0, 0.25, 0.25, 1);
}
.gallery-thumb:hover > img {
  transform: scale(1.1);
}

.maint {
  width: 100vw;
  height: 100vh;
  justify-items: center;
}
.maint__header {
  padding: 2.5vw;
  width: 100vw;
  height: 8rem;
  position: absolute;
  top: 0;
  left: 0;
}
.maint__header svg {
  max-height: 3.2vw;
}
.maint__inner {
  width: 100%;
  justify-content: center;
}
.maint__inner h6 {
  color: #a7a2ac;
}

.organization {
  background-color: #f7f5f8;
  border-radius: 2rem;
  padding: 6rem 0;
}
.organization .group-container {
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  gap: 3rem;
  align-items: center;
}
.organization .content-wrapper {
  width: fit-content;
  background-color: white;
  padding: 2.5rem;
  border-radius: 0.5rem;
}
.organization .content-wrapper .text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.organization .content-wrapper .text-wrapper .title {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  width: 100%;
}
.organization .content-wrapper .text-wrapper .title::before {
  margin-bottom: 1rem;
  top: 0;
  left: 0;
  width: 100%;
  content: "";
  border: 1px solid #f7f5f8;
}