.overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;

	&.purple {
		background-color: get-color(brand-purple, 2);
		opacity: 0.85;
	}
}

div:has(.overlay) {
	position: relative;
}
