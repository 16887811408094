.link-box{
    background-color: get-color(neutral, 1);
    border-radius: 2rem;
    height: 10rem;
    justify-content: space-between;
    padding: 0 2rem 0 1rem;
    align-items: center;

    @include transition(background-color);

    @include media-breakpoint-down(sm) {
        flex-direction: column!important;
        height: auto;
        justify-content: center;
        align-content: center;
        align-items: center;
        padding: 2rem 2rem 2.5rem 2rem;
    }

    &.no-left-icon{
       padding: 0 3rem;

        @include media-breakpoint-down(md) {
            padding: 0;
        }
    }

    &>div{
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    .content-wrapper{
        @include media-breakpoint-down(md) {
            flex-direction: column!important;
            width: 100%;
        }
    }
  

    .text-wrapper{
        height: 100%;
        justify-content: center;
        flex-shrink: 0;

        @include media-breakpoint-down(md) {
            align-items: center;
            gap: .5rem;

            > *{
                text-align: center;
            }
        }
    }

    a{
         @include media-breakpoint-down(md) {
            margin-top: 2rem;
        }
    }
}

a.link-box:hover{
    background-color: get-color(neutral,2);
}