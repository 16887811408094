.nav {
	position: relative;
	z-index: 10;
	background-color: white;

	&.underBorder {
		border-bottom: 1px solid get-color(neutral, 2);

		&:hover{
			border-bottom: 1px solid transparent;
		}
	}

	.logo-wrapper {
		position: relative;

		svg {
			width: calc(10vw);
			min-height: 48px;

			@include media-breakpoint-down(xl) {
				width: calc(15vw);
			}

			@include media-breakpoint-down(lg) {
				width: calc(20vw);
			}
		}
	}

	&.sticky {
		padding: 1rem 0;
		position: fixed;
		width: 100vw;
		top: 0;
		z-index: 99;
		background-color: white;
		border-bottom: 1px solid get-color(neutral, 2);
	}

	&.subpage {
		width: 100vw;
		padding: 1rem 0;
		border-bottom: 1px solid get-color(neutral, 2);
		background-color: white;
	}

	.nav-wrapper {
		position: relative;
		box-sizing: border-box;
		padding: 0 0 0 2.2vw;
		width: 100%;
		justify-content: space-between;

		.menu-wrapper {
			position: relative;
			height: 100%;

			@include media-breakpoint-down(xl) {
				box-sizing: unset !important;
				padding: 5vw;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				align-content: center;
			}

			ul {
				list-style-type: none;
				justify-content: space-between;
				padding: 1rem 3vw;
				gap: 2rem;

				li {
					a {
						transition: color 0.2s cubic-bezier(0, 0.25, 0.25, 1);
						padding: 1rem 0;

						&.menu-active {
							border-bottom: 2px solid get-color(brand-green, 3);
							color: get-color(brand-green, 3);
						}
					}

					a:hover {
						color: get-color(brand-green, 3);
					}
				}

				li:last-child > a {
					margin: 0;
				}
			}
		}
	}

	&.mainpage {
		background-color: transparent;
		position: absolute;
		z-index: 10;
		width: 100%;
		display: flex;
		box-sizing: border-box;
		top: calc(100svh - calc(100svh - 2svw));

		.logo-wrapper {
			top: 1svw;
			position: relative;

			@include media-breakpoint-down(sm) {
				top: 0svw;
				left: 4svw;
			}

			svg {
				@include media-breakpoint-down(sm) {
					width: calc(40svw);
				}
			}
		}

		.nav-wrapper {
			margin: auto 3vw auto 3vw;
			height: 100%;

			.menu-wrapper {
				width: calc(96vw * 0.53);
				height: 100vh * 0.1;

				@include media-breakpoint-down(xl) {
					width: fit-content;
					padding: 2rem;
					height: 2rem;
				}

				ul:not(ul > li > ul) {
					gap: 0;
					align-items: center;
				}

				li.menu-text{
					height: fit-content;
				}
			}
		}
	}

	.sub-menu-link-wrapper {
		opacity: 0;
		display: none;

		top: 2.6rem;
		position: absolute;
		gap: 1.2rem !important;
		z-index: 10;
		padding: 3rem !important;

		font-weight: 500;
		font-size: 0.85rem;
		letter-spacing: 0.05rem;
		
		background-color: white;
		border-radius: 1.5rem;
		box-shadow: 0px 1.4px 13px 0px rgba(0, 0, 0, 0.05), 0px 7px 80px 0px rgba(0, 0, 0, 0.09);

		@include transition();

		li:not(:last-child) {
			border-bottom: 1 solid get-color(neutral, 2);
		}

		li {
			height: 1.5rem;
			white-space: nowrap;
			@include transition(transform);

			&:hover {
				transform: translate(0.5rem, 0);
			}

			a {
				width: 100%;

				&.menu-active {
					border-bottom: 0 !important;
				}
			}
		}
	}

	*:has(.sub-menu-link-wrapper) {
		position: relative;

		& > .sub-menu-link-wrapper {
			@include transition();
		}

		&:hover > .sub-menu-link-wrapper {
			display: flex;
			opacity: 1;
		}
	}

	.menu-wrapper,
	.burger-menu > * {
		pointer-events: none;
	}

	.menu-wrapper > * {
		pointer-events: all;
	}
}

.mobile-menu {
	* {
		@include transition();
	}

	position: fixed;
	width: 100vw;
	height: 0;
	overflow: hidden;
	z-index: 9;
	top: 0;
	background-color: white;

	.nav-wrapper {
		overflow-y: scroll;
		position: relative;
		top: 18svh;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		padding-bottom: 8rem;

		&::-webkit-scrollbar {
			display: none;
		}

		@include media-breakpoint-down(sm) {
			top: 6rem;
			height: calc(100svh - 6rem);
		}
	}

	.nav-link {
		width: 100%;

		&:not(:last-child){
			border-bottom: 1px solid get-color(neutral, 3);
		}

		.menu-text {
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: get-color(ink, 3);
			padding: 2.3rem 5vw;

			&.menu-active{
				color: get-color(brand-green,3);
			}

			@include media-breakpoint-down(xl) {
				padding: 2.3rem 5vw;
				font-size: 2.3rem;
			}

			@include media-breakpoint-down(lg) {
				padding: 2rem 5vw;
				font-size: 1.3rem;
			}

			@include media-breakpoint-down(md) {
				padding: 2rem calc(4vw + 1rem);
			}

			.children {
				display: flex;
				align-content: center;
				justify-content: center;
				align-items: center;

				svg {
					transform-origin: center;
					@include transition();
				}
			}
		}

		a {
			display: flex;
			flex-direction: row;
			width: 100%;
			justify-content: space-between;

			&.menu-active {
				color: get-color(brand-green, 3);
			}

			@include media-breakpoint-down(xl) {
				font-size: 2rem;
				padding: 0.2rem 0;
			}

			@include media-breakpoint-down(lg) {
			}

			@include media-breakpoint-down(md) {
				font-size: 1.1rem;
			}
		}

		.mobile-menu-content-wrapper {
			max-height: 0;
			overflow: hidden;

			.mobile-menu-content {
				padding: 0 5vw;

				@include media-breakpoint-down(xl) {
					padding: 2.3rem 5vw;
					top: 1vh;
				}

				@include media-breakpoint-down(lg) {
					padding: 2rem 5vw;
				}

				@include media-breakpoint-down(md) {
					padding: 0 calc(4vw + 1rem) 1.5rem calc(4vw + 1rem);
				}
			}

			.sub-menu-link-wrapper {
				position: relative;
				top: -1rem;

				li {
					padding: 1rem 0;
					font-weight: 600;
				}
			}
		}

		.mobile-menu-trigger:checked + label > span > svg {
			transform: rotate(-180deg);
			color: get-color(brand-green, 3);
		}

		.mobile-menu-trigger:checked + * + .mobile-menu-content-wrapper {
			max-height: 30rem;
		}
	}

	input[type="checkbox"] {
		display: none;
	}
}
