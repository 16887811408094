/* ABOUT SECTION  */

.section-mainpage-about {
	height: 100vh;

	.container,
	.content-wrapper {
		height: 100%;
	}

	.content-wrapper {
		width: 80%;

		@include media-breakpoint-down(md) {
			width: 90%;
		}

		h4 {
			@include media-breakpoint-down(xl) {
				font-size: 5vw;
			}

			@include media-breakpoint-down(lg) {
				font-size: 7vw;
			}

			@include media-breakpoint-down(md) {
				font-size: 9vw;
			}
		}
	}
}
