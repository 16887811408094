.img-wrapper {

    /* inside layout */
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-content: center;

    /* inside appearance */
    overflow: hidden;

    /* self appearance */
	border-radius: 2rem;
    background-color: get-color(brand-green, 2);
	
	img {
		width: 100%;
        height: auto;
	}

	&.no-aspect {
		aspect-ratio: unset;
		img {
			aspect-ratio: unset;
		}
	}

	&.aspect-2_1-2 {
		aspect-ratio: 2 / 1.2;
		img {
			aspect-ratio: 2 / 1.2;
		}
	}

	&.aspect-3_4 {
		aspect-ratio: 3 / 4;
		img {
			aspect-ratio: 3 / 4;
		}
	}
}
