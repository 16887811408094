@import "foundation/typography";

$breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 1024px,
	xl: 1366px,
	xxl: 1400px,
);

// =============================================================================
// Colors
// =============================================================================

@function get-color($color, $val) {
	@return map-get(map-get($palettes, $color), $val);
}

$palettes: (
	brand-purple: (
		1: #470080,
		2: #2d134e,
		3: #210e39,
	),
	brand-green: (
		1: #4effd3,
		2: #24e0b1,
		3: #00a87f,
	),
	ink: (
		1: #a7a2ac,
		2: #5a555e,
		3: #1b0d26,
	),
	neutral: (
		1: #f7f5f8,
		2: #ecebed,
		3: #dfdde1,
		4: #d6d3d8,
		5: #a8a1ad,
		6: #11091B,	
	),
	yellow: (
		1: #fffddf,
		2: #f8eb90,
		3: #eec21b,
		4: #dfab13,
		5: #c0850e,
		6: #fff34e,
	),
	magenta: (
		1: #fdf4ff,
		2: #f2abfc,
		3: #cc31da,
		4: #a61caf,
		5: #89198f,
		6: #ff64ff,
	),
	red: (
		1: #fef3f2,
		2: #f8aca9,
		3: #d93a43,
		4: #b31d29,
		5: #961b29,
		6: #ff4f4f,
	),
	blue: (
		1: #ecf9ff,
		2: #b2e7ff,
		3: #0051cb,
		4: #0848a0,
		5: #0a2c61,
		6: #4790fe,
	),
) !default;

// =============================================================================
// Typography
// =============================================================================

* {
	font-family: "Poppins";
}

.text-hero {
	font-size: clamp(3.125rem, 2.354vw + 2.835rem, 6.5rem);
	line-height: 1.1;
}

.text-huge {
	font-size: clamp(4.125rem, 12.304vw + 3.235rem, 15rem);
}

h1,
.h1 {
	font-size: clamp(3.125rem, 7.304vw + 1.235rem, 10rem);
}

h2,
.h2 {
	font-size: clamp(2.5rem, 5.843vw + 0.988rem, 8rem);
}

h3,
.h3 {
	font-size: clamp(2.5rem, 3.062vw + 0.225rem, 5.5rem);
}

h4,
.h4 {
	font-size: clamp(2rem, 1.594vw + 1.588rem, 4rem);
}

h5,
.h5 {
	font-size: clamp(1.25rem, 0.797vw + 1.044rem, 2rem);
}

h6,
.h6 {
	font-size: clamp(1.25rem, 1.594vw + 1.588rem, 1.75rem);
}

p,
.p {
	font-size: clamp(1rem, 0.466vw + 0.806rem, 1.125rem);
}

p.small,
.p.small {
	font-size: clamp(0.75rem, 0.133vw + 0.716rem, 1.875rem);
}

p.large,
.p.large {
	font-size: clamp(1.25rem, 0.531vw + 1.113rem, 1.75rem);
}

.overline {
	font-size: clamp(0.563rem, 0.199vw + 0.511rem, 0.75rem);
	letter-spacing: 0.225rem;
	@extend .text-uppercase;
}

.caption {
	font-size: clamp(0.688rem, 0.133vw + 0.653rem, 0.813rem);
	@extend .text-uppercase;
}

.menu-text {
	font-size: clamp(0.588rem, 3.199vw + 0.753rem, 0.85rem);
	@extend .text-uppercase;
	@extend .text-semibold;
	letter-spacing: 0.1rem;

	@include media-breakpoint-down(xl) {
		font-size: 3rem;
		letter-spacing: 0.3rem;
	}

	@include media-breakpoint-down(lg) {
		font-size: 1.8rem;
		letter-spacing: 0.2rem;
	}

	@include media-breakpoint-down(md) {
		font-size: 1.5rem;
		letter-spacing: 0.1rem;
	}
}

.label,
label {
	font-size: clamp(0.588rem, 3.199vw + 0.753rem, 0.85rem);
	@extend .text-uppercase;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
	color: get-color(ink, 2);
}

.button-label {
	font-size: clamp(1rem, 0.9642857142857143rem + 0.17857142857142858vw, 1rem);
	@extend .text-uppercase;
	@extend .text-regular;
	line-height: 2;
	letter-spacing: 0.1rem;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
h6 > *,
.h6 *,
.h6,
p,
p * {
	line-height: 1.6;
	margin: 0;
	padding: 0;
	max-width: 100%;
}
