.hero {
	position: relative;
	margin-top: 2vw;
	height: calc(100vh - 4vw);
	width: 96vw;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;

	@include media-breakpoint-down(xl) {
		height: calc(100svh - 4vw);
	}

	#hero-mask,
	#subpage-hero-mask {
		display: flex;
		position: absolute;
	}

	.slider-container {
		height: calc(100vh - 4vw);
		width: 96vw;
		overflow: hidden;
		z-index: 0;

		@include media-breakpoint-down(xl) {
		height: calc(100svh - 4vw);
	}

		.slider-wrapper {
			height: 100%;
		}

		.slider-items {
			height: 100%;
			position: relative;

			.slider-item {
				display: flex;
				align-items: center;
				align-content: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				display: none;
				position: absolute;

				.slider-text-wrapper {
					position: absolute;
					z-index: 2;
					height: 100%;
					max-width: 40%;
					margin-left: 4vw;
					gap: 2rem;
					justify-content: center;

					@include media-breakpoint-down(xl) {
						box-sizing: border-box;
						max-width: 95%;
						justify-content: end;
						height: 85%;
					}

					h2 {
						@include media-breakpoint-only(xs) {
							font-size: 16vw;
						}

						@include media-breakpoint-down(xl) {
							font-size: 8vw;
						}

						@include media-breakpoint-down(sm) {
							font-size: 12vw;
						}
					}

					a {
						position: relative;
						z-index: 2;

						@include media-breakpoint-down(sm) {
							display: none;
						}
					}
				}

				.slider-dragger {
					position: absolute;
					width: 100vw;
					height: 100%;
					z-index: 1;
					//background-color: red;
					left: -5vw;
					top: 0;
				}

				&.active {
					display: block;
					z-index: 5;
				}

				&.actived {
					display: block;
					z-index: 2;
				}

				.img-wrapper {
					top: 0;
					width: 100%;
					height: auto;
					min-height: 103%;
					position: absolute;
					object-fit: cover;

					img {
						width: 100%;
						height: auto;
						min-height: 103%;

						@include media-breakpoint-down(lg) {
							bottom: 0;
						}
					}
				}
			}
		}
	}

	&-bottom {
		height: calc(calc(100vh - 4vw) * 0.1);
		width: 96vw;
		box-sizing: border-box;
		justify-content: space-between;
		position: absolute;
		bottom: 0;
		z-index: 8;

		ul {
			list-style-type: none;
			padding: 0 2vw;
			gap: 0.5vw;

			li {
				width: 2rem;
				height: 2vw;
				justify-content: center;
			}
		}

		.scroll-down {
			box-sizing: border-box;
			padding: 1svw 2vw;
			justify-content: center;

			svg{
				transform: scale(1.2);
				width: calc(80%);
				height: auto;
			}
			
		}
	}
}

.slider-buttons {
	@include media-breakpoint-down(sm) {
		position: absolute;
		right: 0;
	}

	.slider-button {
		position: relative;
		background-color: get-color(neutral, 3);
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 10rem;

		@include transition(transform);

		&:hover {
			cursor: pointer;
			transform: scale(1.3);
		}

		&.active {
			background-color: get-color(brand-green, 2);
			width: 0.8rem;
			height: 0.8rem;

			&:hover {
				transform: scale(1);
			}
		}
	}
}
.scroll-down-ani {
	@include transition(transform);
	animation: SDAni forwards 2s infinite;
}

@keyframes SDAni {
	0% {
		transform: translateY(-5px);
	}
	50% {
		transform: translateY(5px);
	}
	100% {
		transform: translateY(-5px);
	}
}

.subpage-hero-img-container {
	width: 96%;
	aspect-ratio: 1800/1012;

	.hero-subpage-wrapper {
		position: relative;
		width: 100%;
		overflow: hidden;
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: center;
		aspect-ratio: 1800/1012;

		img{
			min-width: 100%;
			height: auto;
			aspect-ratio: 1800/1012;
		}
	}
	.subpage-hero-SVG {
		position: absolute;
	}
}
