.accordion-list {
	.accordion-item {
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow: hidden;
		border-bottom: 1px solid get-color(neutral, 3);

		@include transition();

		.accordion-content-wrapper{
			height: 0;
			display: flex;
			overflow: hidden;
			flex-direction: column;
			height: 0px;
			max-height: 0px;
			@include transition();
		}

		.accordion-title {
			letter-spacing: 0;
			display: flex;
			justify-content: space-between;
			align-items: center;
			min-height: 6rem;
			text-transform: none;
			color: get-color(ink, 3);

			@include transition();

			&:hover {
				cursor: pointer;
				color: get-color(brand-green, 3);
			}

			&::after {
				content: "+";
				right: 0;
				margin-left: 2rem;
				display: inline-block;
				position: relative;
				@include transition();
			}
		}

		.accordion-trigger:checked + .accordion-title::after {
			transform: rotate(-45deg);
		}

		.accordion-trigger:checked + .accordion-title {
			color: get-color(brand-green, 3);
		}



		.accordion-trigger:checked + * + .accordion-content-wrapper{
			height: 100%;
			max-height: none;
			padding: 1rem 0 2rem 0;
		}

		input[type="checkbox"] {
			display: none;
		}
	}
}
