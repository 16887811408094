.mainpage-section-title {
	display: flex;
	padding: 8.5rem 0rem;
	flex-direction: column;
	align-items: flex-start;
	gap: 6.5rem;
	overflow-x: hidden;

	@include media-breakpoint-down(md) {
		padding: 4rem 0rem;
		gap: 2.5rem;
	}

	.title-wrapper {
		gap: 12vw;
		width: 100%;

		h4 {
			@include media-breakpoint-down(md) {
				font-size: 20vw;
			}
		}
	}

	&__desc {
		@include media-breakpoint-down(md) {
			width: 87vw;
		}

		h4 {
			@include media-breakpoint-down(md) {
				font-size: 7vw;
			}
		}
	}

	&__button-wrapper {
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: center;

		@include media-breakpoint-down(lg) {
			margin: 1rem 0 3rem 0;
			justify-content: flex-start;
		}
	}
}
