clipPath{
    display: flex;
}

.landing-product {
	margin: 9rem 0 0rem 0;

	@include media-breakpoint-down(md) {
		margin: 4rem 0 0rem 0;
	}

	.landing-product-list-wrapper {
		width: 100%;

		&__column {
			gap: 18rem;
		}

		.landing-product-items {
			gap: 9rem;
		}

		.landing-product-item {
			box-sizing: border-box;
			position: relative;
			justify-content: center;

			&-wrapper {
				justify-content: center;
			}

			img {
				width: 100%;
				height: auto;
				position: relative;
			}

			.landing-product-img-wrapper {
				width: 100%;
				height: auto;
				border-radius: 2rem 0 2rem 2rem;
				overflow: hidden;


				&.reference-item {
					box-sizing: border-box;
					background-color: get-color(neutral,1);
					position: relative;
					width: 100%;
					height: 100%;
					justify-content: left;
					padding: 5rem 3rem 2rem 2.5rem;

					.reference-item__text-wrapper {
						margin-top: 8rem;
						gap: 0.5rem;
						width: 80%;
					}

					.buttons {
						position: relative;
						margin-top: 3rem;
						padding: 1rem 2rem;
					}
				}
			}

			.landing-product-item-text-wrapper {
				margin: 2rem 0;
				width: 50%;


				@include media-breakpoint-down(xxl) {
					width: 80%;
				}

				@include media-breakpoint-down(sm) {
					width: 100%;
				}


				.overline {
					margin-bottom: 1rem;
				}
			}
		}
	}

   /*  .landing-product-item-mask-2{
        @include md {
            transform: translate(-10px,0px);
        }
    }

    .landing-product-item-mask-1{
        @include md {
            transform: translate(0px,-20px);
        }
    } */
}
