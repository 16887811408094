.card {
	position: relative;
	margin-top: 4rem;

	&.reference {
		.tag {
			position: absolute;
			z-index: 2;
			top: 2rem;
			left: 2rem;

			padding: 0.5rem 1rem;
			border-radius: 3rem;
			background-color: white;
		}
	}

	&.news {
		margin-top: 6rem;

		&.big {
			.text-wrapper {
				position: absolute;
				padding: 3rem;
				bottom: 0;
				left: 0;
				width: 50%;
			}
		}
	}

	.text-wrapper {
		gap: 1rem;
	}

	&.values {
		background-color: get-color(neutral, 1);
		justify-content: center;
		border-radius: 1.5rem;
		aspect-ratio: 4/3;
		height: auto;
		padding: 2rem;
		gap: 1rem;
		margin-top: 0;
	}

	&.management {
		.img-wrapper {
			@include media-breakpoint-down(xl) {
				width: 100%;
				height: auto;
				aspect-ratio: 1;
			}
		}
	}
}

a.card {
	img {
		@include transition();
	}

	&:hover img {
		transform: scale(1.05);
		opacity: 0.8;
	}
}

.card.certificate {
	.content-wrapper {
		background-color: get-color(neutral, 1);
		border-radius: 2rem;
		padding: 0;
	}
	.img-wrapper {
		background-color: transparent;
		height: auto;
		width: 33%;
		margin: 0;
		aspect-ratio: unset;

		img {
			width: 100%;
			height: auto;
		}
	}

	.text-wrapper {
		gap: 0.5rem;
		width: 50%;
	}

	a {
		margin-top: 1rem;
	}
}
