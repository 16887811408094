.section-patners {
	position: relative;

	h6{
		width: 80%;
		text-align: center;
	}

	.patners-wrapper{
		position: relative;
		margin: 8rem 0;
		justify-content: space-between;

		img{
			height: 6.5rem;
			width: auto;
		}
	}

	.container{
		justify-content: center;
	}
}
