// =============================================================================
// Style
// =============================================================================

.text-align-center{
	text-align: center;
}

.text-align-right{
	text-align: right;
}

.text-align-left{
	text-align: left;
}

.text-thin {
	font-weight: 300;
}

.text-regular {
	font-weight: 500;
}

.text-semibold {
	font-weight: 600;
}

.text-italic {
	font-style: italic;
}

.text-underline {
	text-decoration: underline;
}

.text-uppercase{
	text-transform: uppercase;
}

.text-lowercase{
	text-transform: lowercase;
}


// =============================================================================
// Colors
// =============================================================================

.text-color-white{
	color: white
}

.text-color-black{
	color: black;
}

.text-color-brand-purple-3{
	color: get-color(brand-purple, 3);
}
.text-color-brand-purple-2{
	color: get-color(brand-purple, 2);
}
.text-color-brand-purple-1{
	color: get-color(brand-purple, 1);
}

.text-color-brand-green-3{
	color: get-color(brand-green, 3);
}
.text-color-brand-green-2{
	color: get-color(brand-green, 2);
}
.text-color-brand-green-1{
	color: get-color(brand-green, 1);
}

.text-color-ink-3{
	color: get-color(ink, 3);
}
.text-color-ink-2{
	color: get-color(ink, 2);
}
.text-color-ink-1{
	color: get-color(ink, 1);
}

.text-color-error{
	color:get-color(red,3)
}