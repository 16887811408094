/* PRODUCT SECTION  */

.section-product{
	overflow: hidden;
}

.product- {
	&list {
		position: relative;
		height: 100svh;
		width: fit-content;
		background-color: get-color(neutral,6);
		
		&::-webkit-scrollbar{
			display: none;
		}

		@include media-breakpoint-down(xl) {
			background-color: white;
			width: 100%;
			height: auto;
			overflow-y: auto;
		}
	}

	&item-wrapper {
		position: relative;
		overflow: hidden;
		min-width: 85vw;
		height: 100svh;
		background-color: get-color(brand-green,3);

		@include media-breakpoint-down(xl) {
			height: 80svh;
		}

		@include media-breakpoint-down(lg) {
			height: 70svh;
		}

		@include media-breakpoint-down(md) {
			height: 50svh;
		}

		img {
			z-index: 0;
			position: absolute;
			height: 100%;
			width: auto;
			object-fit: cover;
			min-width: 100%;

            @include transition(opacity);
		}
	}

    &item-wrapper:hover > img{
        opacity: .85;
    }


	&img-gradient {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1;
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 43.75%, rgba(0, 0, 0, 1) 100%);
	}

	&detail {
		position: absolute;
		z-index: 2;
		gap: 1rem;
		width: 50%;
		height: fit-content;
		left: 0;
		bottom: 0;
		margin: 5vw;

		@include media-breakpoint-down(xl) {
			width: auto;
		}

		h5{
			margin-top: 1.5rem;
			display: none;
		}

		h6 {
			margin-top: 2rem;
			opacity: 0.8;
		}
	}

	&more-wrapper {
		height: 100%;
		width: 25vw;
		justify-content: center;
		position: relative;
		color: get-color(brand-purple, 3);
        background-color: white;

		transition: color 0.2s cubic-bezier(0, 0.25, 0.25, 1);
		transition: background-color 0.2s cubic-bezier(0, 0.25, 0.25, 1);

		@include media-breakpoint-down(xl) {
			height: auto;
			width: auto;
			border-top: 1px solid get-color(neutral, 2);
		}

		.more-button {
			position: relative;
			width: fit-content;
			height: fit-content;

			@include media-breakpoint-down(xl) {
				width: 50vw;
			}

			@include media-breakpoint-down(md) {
				width: 70vw;
			}
		}

		.more-detail-text {
			gap: 1rem;
			justify-content: center;
		}

		&:hover {
			background-color: get-color(ink,3);
			color: white !important;
		}
	}
}
