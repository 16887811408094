.maint{
    width: 100vw;
    height: 100vh;
    justify-items: center;

    &__header{
        padding: 2.5vw;
        width: 100vw;
        height: 8rem;
        position: absolute;
        top: 0;
        left: 0;

        svg{
            max-height: 3.2vw;
        }
    }

    &__inner{
        width: 100%;
        justify-content: center;

        h6{
            color: get-color(ink,1);
        }
    }
}