/* REFERENCES SECTION  */

.section-count{
	overflow: hidden;
	position: relative;
	padding: 40svh 0;

	@include media-breakpoint-down(xl) {
		padding: 25svh 0;
	}
}