.input-wrapper {
	textarea {
		display: flex;
		padding: 1.25rem 1.5rem;
        height: 14rem;
		align-items: center;
        border-radius: 1rem;
        border: 2px solid transparent;
        background-color: white;
        appearance: none;
        resize: none;

        @include transition(border);

        &:focus{
            border: 2px solid get-color(neutral, 3);
        }

        &::placeholder{
            font-size: clamp(1rem, 0.466vw + 0.806rem, 1rem);
            color: get-color(ink,1);
            font-weight: 200;
            width: 100%;
           
        }

        &:invalid {
			border: 1px solid get-color(red, 4);
		}
	}

    .helper{
        margin-top: -.5rem;

        @include transition(display);

        &.active{
            display: block;
        }
    }
}
