.custom-video-player-wrapper {
	//cursor: none;
	overflow: hidden;
	border-radius: 4rem;
	justify-content: center;

	&:hover {
		cursor: pointer;
	}

	.overlay-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
	}

	&.full-width {
		width: 100%;
		height: 100svh;

		@include media-breakpoint-down(xl) {
			height: 80svh;
			border-radius: 3rem;
		}

		@include media-breakpoint-down(lg) {
			height: 40svh;
		}

		@include media-breakpoint-down(sm) {
			height: 70svh;
		}
	}

	.play-button {
		position: absolute;
		z-index: 2;
		@include transition(transform);

		@include media-breakpoint-only(md) {
			transform: scale(.7);
			top: 25%;
		}

		@include media-breakpoint-down(sm) {
			top: 20%;
			transform: scale(.6);
		}

		&:hover {
			transform: scale(1.1);

			@include media-breakpoint-down(md) {
				transform: scale(.8);
			}
		}
	}

	.text-wrapper {
		gap: 1rem;
		position: absolute;
		z-index: 2;
		width: 40%;
		bottom: 5rem;
		left: 5rem;

		@include media-breakpoint-down(xl) {
			width: 80%;
			bottom: 3rem;
			left: 3rem;
		}

		@include media-breakpoint-down(md) {
			width: 100%;
			padding: 2rem;
			bottom: 0;
			left: 0;

			h6:not(.overline){
				font-size: 1.2rem;
			}
		}
	}

	.product-fullscreen-video {
        justify-content: center;
		width: 100vw;
		height: 100svh;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10000;
        overflow: hidden;

        .play-button{
            opacity: 0;
            z-index: 10;
        }

        .video-time-wrapper{
            z-index: 2;
            position: absolute;
            width: 100%;
            height: 100%;

            span{
                position: absolute;
                bottom: 2rem;
                left: 0;
            }

            .video-overlay{
                width: 100%;
                height: 100%;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.00) 20.98%);
            }
        }
	}
}
