.opacity-64 {
	opacity: 0.64;
}

.opacity-80 {
	opacity: 0.8;
}

.vh-80 {
	height: 80vh;
}

.vh-100 {
	height: 100vh;
}

.width-85 {
	width: 85%;
}

.mt-8rem {
	margin-top: 8rem;
}

.mt-13rem {
	margin-top: 13rem;
}

.flex-end {
	align-items: flex-end;
}

.h-center {
	flex-direction: row;
	justify-content: center;
}

.v-center {
	flex-direction: column;
	justify-content: center;
}

.img-cover {
	width: 100%;
	height: auto;
	min-height: 100%;
	position: absolute;
	object-fit: cover;
}

div:has(> .img-cover) {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	align-content: center;
	justify-content: center;
}

img.square {
	aspect-ratio: 1;
}

.rounded-mid {
	border-radius: 2rem;
}

.gap-05rem {
	gap: 0.5rem !important;
}

.gap-1rem {
	gap: 1rem !important;
}

.gap-2rem {
	gap: 2rem !important;
}

.gap-3rem {
	gap: 3rem !important;
}

.p-relative {
	position: relative;
}

.p-absolute {
	position: absolute;
}

svg {
	overflow: visible;
}

.row-gap-3rem {
	row-gap: 3rem;
}

.noClick {
	pointer-events: none;
}

.noClickParent > * {
	pointer-events: none;
}

a:hover {
	cursor: pointer;
}

.hide {
	display: none !important;
}

.j-center {
	justify-content: center;
}

.border-box {
	box-sizing: border-box;
}

.h-100p {
	height: 100%;
}

.w-100p {
	width: 100%;
}

/* =================================== */

.hide-laptop {
	@include media-breakpoint-up(xl) {
		display: none!important;
	}
}

.hide-tablet-landscape {
	@include media-breakpoint-down(xl) {
		display: none!important;
	}
}

.hide-tablet-portrait {
	@include media-breakpoint-down(lg) {
		display: none!important;
	}
}

.hide-mobile-landscape {
	@include media-breakpoint-down(md) {
		display: none!important;
	}
}

.hide-mobile-portrait {
	@include media-breakpoint-down(sm) {
		display: none!important;
	}
}


/* *** */

.show-mobile-portrait {
	@include media-breakpoint-down(sm) {
		display: block!important;
	}
}

.show-mobile-landscape {
	@include media-breakpoint-down(md) {
		display: block!important;
	}
}

.show-tablet-portrait {
	@include media-breakpoint-down(lg) {
		display: block!important;
	}
}

.show-tablet-landscape {
	@include media-breakpoint-down(xl) {
		display: block!important;
	}
}



/* =================================== */

.guideBorder{
	border: 1px solid red;
}

.guideBg{
	background-color: red;
}


/* =================================== */

html.lenis {
	height: auto;
}

.lenis.lenis-smooth {
	scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
	overscroll-behavior: contain;
}

.lenis.lenis-stopped {
	overflow: hidden;
}

.lenis.lenis-scrolling iframe {
	pointer-events: none;
}
