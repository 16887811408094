/* WHY SECTION  */

.section-why {
	position: relative;
	padding: 30vh 0 0 0;
	color: get-color(brand-purple, 3);
	

	@include media-breakpoint-down(xl) {
		padding: 15vh 0 0 0;
	}

	@include media-breakpoint-down(lg) {
		padding: 10vh 0 0 0;
	}

	.why-list-image {
		flex-wrap: wrap;
		gap: 3rem;

		&::-webkit-scrollbar{
			display: none;
		}

		@include media-breakpoint-down(xl) {
			
			position: relative;
			padding: 0 3.5vw;
			left: calc(-2vw - 1.5rem);
			margin-top: 4rem;
			gap: 1.2rem;
			display: flex;
			flex-direction: row;
			overflow: scroll;
			width: 100vw;
			flex-wrap: nowrap;
		}

		@include media-breakpoint-down(sm) {
			left: calc(-2vw - 1rem);
		}

	}

	.text-wrapper {
		width: 80%;
		gap: 1rem;

		@include media-breakpoint-down(lg) {
			width: 100%;
		}


		.buttons {
			margin-top: 2rem;
		}

		h2 {
			line-height: 1.2;
		}

		p {
			line-height: 1.5;
		}

	}

	.sticky-content {
		height: 100vh;
		justify-content: center;

		@include media-breakpoint-down(xl) {
			height: auto;
		}
	}

	.why-list-item {
		overflow: hidden;
		border-radius: 2rem;
		justify-content: center;
		position: relative;

		@include media-breakpoint-down(xl) {
			min-width: 55svw;
		}

		@include media-breakpoint-down(lg) {
			min-width: 80svw;
			border-radius: 1.5rem;
		}

		h4 {
			position: absolute;
			margin: 3rem;
			padding: 0;
			line-height: 1.2;
			top: 0;
			left: 0;

			@include media-breakpoint-down(lg) {
				margin: 2rem;
				font-size: calc(5vw);
			}

		}

		img {
			width: 100%;
			height: auto;
		}
	}
}
