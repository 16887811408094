/* SLOGAN SECTION  */

.section-slogan {
	height: 100svh;

	@include media-breakpoint-down(md) {
		height: 60svh;
	}

	.container,
	.content-wrapper {
		height: 100%;
		width: 100%;
	}

	.content-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
	}
}
