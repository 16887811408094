.subpage {
	&-margin {
		margin: 10rem 0 0 0;

		@include media-breakpoint-down(xl) {
			margin: 6rem 0 0 0;
		}
	}

	&-title {
		&.product {
			svg {
				margin-bottom: 2rem;
			}
		}
	}

	&-content-margin {
		&-xsmall {
			margin-top: 1rem;
		}
		&-small {
			margin-top: 2rem;
		}
		&-mid {
			margin-top: 3rem;
		}
		&-large {
			margin-top: 5rem;
		}
		&-xlarge {
			margin-top: 18rem;
			@include media-breakpoint-down(xl) {
				margin-top: 9rem;
			}
		}
	}

	&-product-content-image {
		margin: 4rem 0;
		overflow: hidden;
		border-radius: 2rem;

		img {
			width: 100%;
			height: auto;
		}

		&.square, .square {
			aspect-ratio: 875 / 720;
		}

		&.landscape, .landscape {
			aspect-ratio: 1750 / 1064;
		}

		@include media-breakpoint-down(xl) {
			margin: 3rem 0;
		}

		&.small-margin {
			margin: 3rem 0;
		}

		&.xSmall-margin {
			margin: 2rem 0;
		}
	}
}

.sub-title-margin {
	margin-bottom: 2rem;
}
