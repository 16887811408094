.contact {
	&-map-wrapper {
		border-radius: 2rem;
		overflow: hidden;
		position: relative;
		min-width: 100%;
		height: 70vh;
		min-height: 100%;
		max-height: 800px;

		.content-wrapper {
			position: absolute;
			padding: 3rem;
			left: 0;
			bottom: 0;
			z-index: 2;
		}
	}

	&-adress-wrapper {
		margin: 0 3rem;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;

		.address-line-wrapper {
			padding: 2rem 0 2rem 0;
			border-bottom: 1px solid get-color(neutral, 3);

			&:last-child {
				border-bottom: 0;
			}
		}
	}

	&-job-form {
		padding: 10rem 0;
		background-color: get-color(neutral, 1);
		border-radius: 3rem;
	}
}
