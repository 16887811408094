ul.bullet,
ol.bullet {
	line-height: 1.5;
	position: relative;
	margin-top: 3rem;
	width: 100%;

	li {
		padding: 0.5rem 0rem;
		position: relative;

		&.add-margin {
			margin-top: 6rem;
		}

		.full-width {
			position: relative;
			margin-left: -2rem;
			width: calc(100% + 2rem);
			max-width: unset;

			@include media-breakpoint-down(xl) {
				width: calc(100% + 2rem);
				margin-left: -2rem;
			}
		}
	}
}

ol.bullet {
	li {
		margin-left: 2rem;
		margin-top: 2rem;
	}
}

ul.bullet {
	list-style-type: square;
	padding-left: 1.5rem;

	&.bullet-green {
		li::marker {
			margin-left: 1rem;
			color: get-color(brand-green, 3);
		}
	}

	&.bullet-ink {
		li::marker {
			color: get-color(brand-green, 3);
		}
	}

	&.bullet-purple {
		li::marker {
			color: get-color(brand-purple, 3);
		}
	}

	&.square {
		li::before {
			margin-right: 1.2rem;
		}
	}
}

ul.grid {
	columns: 2;
	column-gap: 3rem;
	column-fill: balance;
}
