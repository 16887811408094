wave-audio-path-player {
	border-radius: 2rem;
	background: rgba(36, 224, 177, 0.1);
  padding: 3rem;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  .slider{
    flex-shrink: 1;
  }
}