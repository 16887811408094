
.footer {
	width: 100vw;
	margin-top: 15rem;
	margin-bottom: 2vw;
	position: relative;
	height: 56rem;
	justify-content: center;

	@include media-breakpoint-down(xl) {
		height: fit-content;
	}

	@include media-breakpoint-down(md) {
		margin-bottom: 6rem

	}

	#footer-mask-svg {
		position: absolute;
		z-index: -10;
	}

	.footer-wrapper {
		width: 96%;
		height: 100%;
		background-color: get-color(brand-purple, 2);
		position: relative;

		@include media-breakpoint-down(xl) {
			padding: 13rem 0 12rem 0;
		}

		.brand-pattern {
			top: 0;
			right: 0;
			position: absolute;

			svg{
				@include media-breakpoint-down(sm) {
					width: calc(50vw);
				}
			}
		}

		.footer-links-wrapper {
			margin: auto;
			width: 80%;
			position: relative;

			@include media-breakpoint-down(xl) {
				width: 88%;
			}

			@include media-breakpoint-down(lg) {
				width: 85%;
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				flex-flow: wrap-reverse;
			}

			@include media-breakpoint-down(sm) {
				margin-bottom: 2rem;
			}

			.footer-left-side {
				width: 40%;

				@include media-breakpoint-down(lg) {
					width: 100%;
					margin-top: 7rem;
					justify-content: space-between;

					& > .content-wrapper {
						width: 100%;
						display: flex;
						flex-direction: column;
						height: 100%;

						& > div:last-child {
							display: flex;
							justify-content: space-between !important;
							flex-direction: row;
							gap: 2rem;
							margin-bottom: 4rem;

							@include media-breakpoint-down(sm) {
								flex-direction: column;
								gap: 0rem;
							}
						}
					}
				}

				.contact-button {
					margin-top: 2rem;

					.title {
						gap: 1rem;
					}

					h6.big {
						margin-left: 2.5rem;
						opacity: 0.6;
						font-size: 1.6rem;
						width: fit-content;

						@include transition();

						&:hover {
							opacity: 1;
						}

						/* @include media-breakpoint-down(md) {
							font-size: 1.5vw;
						} */
					}
				}

				p {
					line-height: 1.6;
				}

				.footer-form-wrapper {
					gap: 2rem;
					margin-bottom: 2rem;

					.buttons {
						width: fit-content;
					}

					p {
						width: 60%;

						@include media-breakpoint-down(lg) {
							width: 80%;
						}
					}
				}
			}

			.footer-right-side {
				@include media-breakpoint-down(lg) {
					width: 100%;
				}

				&__col {
					width: 100%;
					height: 100%;
				}

				.content-wrapper {
					gap: 8vw;

					@include media-breakpoint-down(lg) {
						gap: 0;
						display: flex;
						flex-direction: row;
						justify-content: space-between;
					}

					@include media-breakpoint-down(sm) {
						flex-direction: column!important;
						gap: 1.5rem;
					}

				}

				ul {
					gap: 1.5rem;

					li {
						list-style-type: none;
						white-space: nowrap;

						@include media-breakpoint-down(sm) {
							& > * {
								font-size: 6.5vw!important;
							}
						}

						a {
							opacity: 0.72;
							transition: opacity 0.4s cubic-bezier(0, 0.25, 0.25, 1);

							&:hover {
								opacity: 1;
							}
						}
					}
				}
			}
		}

		.social-wrapper {
			position: absolute;
			height: 15%;
			left: 0;
			bottom: 0;
			justify-content: space-between;
			align-items: center;
			padding: 0 0 0 2vw;
			width: 68vw;

			@include media-breakpoint-down(xl) {
				height: fit-content;
				bottom: 5%;
				gap: 2rem;
				padding: 0 1vw 0 3vw;
				align-items: flex-end;
			}

			@include media-breakpoint-down(lg) {
				flex-direction: row!important;
				padding: 0 5vw;
				bottom: 2%;
				flex-flow: wrap-reverse;
				align-items:flex-start
			}

			@include media-breakpoint-down(sm) {
					width: 100%;
				}

			&-items {
				@include media-breakpoint-down(xl) {
					display: flex;
					flex-direction: row;
					justify-content: flex-end;
				}

				@include media-breakpoint-only(md) {
					justify-content: flex-start;
				}
				
			}

			.dahi-copyright {
				opacity: 0.72;
				white-space: nowrap;
			}

			ul {
				gap: 1rem;
				justify-content: center;

				@include media-breakpoint-down(xl) {
					flex-wrap: wrap;
					width: 85%;
					display: flex;
    				justify-content: flex-end;
				}

				@include media-breakpoint-only(md) {
					width: 75%;
					justify-content: flex-start;
				}

				@include media-breakpoint-down(md) {
					width: 100%;
					height: fit-content;
				}

				@include media-breakpoint-down(sm) {
					justify-content: flex-start;
				}

				li {
					list-style-type: none;
					white-space: nowrap;
					display: flex;
					justify-content: center;

					a {
						transition: opacity 0.4s cubic-bezier(0, 0.25, 0.25, 1);
						opacity: 0.72;

						&:hover {
							opacity: 1;
						}
					}
				}
			}
		}
	}

	.made-by-wlitz {
		position: absolute;
		bottom: 0;
		right: 2vw;
		width: 25%;
		justify-content: center;
		height: 15%;
		align-items: center;

		@include media-breakpoint-down(lg) {
			box-sizing: unset !important;
			height: fit-content;
			padding: 3rem 1rem;
		}

		@include media-breakpoint-down(md) {
			right: 0;
			padding: 0;
			bottom: -3rem;
			width: 100%;
		}
	}
}
