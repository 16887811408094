/* NEWS SECTION  */

.section-news {
	background-color: #2e164d;
	color: white;
	border-radius: 0 0 2rem 2rem;

	position: relative;
	z-index: 1;

	padding-bottom: 30vh;
	margin-bottom: 10vh;
	width: 100%;

	@include media-breakpoint-down(xl) {
		padding-bottom: 20vh;
	}

	@include media-breakpoint-down(lg) {
		padding-bottom: 10vh;
	}

	.top-bg {
		width: 100%;
		height: 10rem;
		top: -8rem;
		display: inline-block;
		position: absolute;
		z-index: -1;
	}

	.news-list-wrapper {
		display: grid;

		grid-template: 1fr 1fr 1fr 1fr 1fr / 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

		gap: 3rem;
		height: 90vh;

		@include media-breakpoint-down(xl) {
			height: auto;
			grid-template: 1fr 1fr 1fr 1fr / 1fr 1fr 1fr 1fr;
		}

		@include media-breakpoint-down(lg) {
			height: auto;
			gap: 1rem;
			grid-template: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr / 1fr 1fr 1fr 1fr;
		}

		@include media-breakpoint-down(sm) {
			
			gap: 1rem;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
		}

		.news-item {
			position: relative;
			border-radius: 2rem;
			overflow: hidden;

			color: get-color(brand-purple, 3);

			@include media-breakpoint-down(xl) {
				border-radius: 1.5rem;
				height: 100%;
			}

			@include media-breakpoint-down(sm) {
				height: auto;
				width: 100%;
			}

			&:hover > .news-text-wrapper {
				transform: translateY(-0.4rem);
				color: get-color(brand-purple, 2);
			}

			.news-text-wrapper {
				position: absolute;
				padding: 2.5rem;
				gap: 1rem;
				left: 0;
				bottom: 0;
				width: 100%;
				height: fit-content;

				@include transition();

				h4 {
					line-height: 1.2;
					margin-top: 1rem;

					@include media-breakpoint-down(lg) {
						margin-bottom: 0;
					}
				}

				h6:not(.overline) {
					@include media-breakpoint-down(lg) {
						font-size: 1.5rem;
					}
				}

				@include media-breakpoint-down(sm) {
					position: relative;
				}
			}

			img {
				position: absolute;
				object-fit: cover;
				width: 100%;
				height: auto;
				min-height: 100%;
				z-index: -2;
			}

			.news-gradient {
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: -1;
				background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 43.75%, rgba(255, 255, 255, 1) 100%);
			}

			&.big {
				grid-row: 1 / 6;
				grid-column: 1 / 5;

				@include media-breakpoint-down(xl) {
					grid-row: 1 / 3;
					grid-column: 1 / 5;
				}

				@include media-breakpoint-down(lg) {
					grid-row: 1 / 5;
					grid-column: 1 / 5;
				}

				@include media-breakpoint-down(sm) {
					height: 40svh;
				}

				.news-text-wrapper {
					position: absolute;

					@include media-breakpoint-down(xl) {
						width: 70%;
					}

					@include media-breakpoint-down(md) {
						width: 100%;
					}
				}
			}

			&.wide {
				background-color: get-color(blue, 1);
				grid-row: 1 / 4;
				grid-column: 5 / 9;

				@include media-breakpoint-down(xl) {
					grid-row: 3 / 5;
					grid-column: 1 / 3;
				}

				@include media-breakpoint-down(lg) {
					grid-row: 5 / 7;
					grid-column: 1 / 5;
				}
			}
			&.box-1 {
				background-color: get-color(yellow, 1);
				grid-row: 4 / 6;
				grid-column: 5 / 7;

				@include media-breakpoint-down(xl) {
					grid-row: 3 / 3;
					grid-column: 3 / 5;
				}

				@include media-breakpoint-down(lg) {
					grid-row: 7 / 9;
					grid-column: 1 / 3;
				}
			}
			&.box-2 {
				background-color: get-color(magenta, 1);
				grid-row: 4 / 6;
				grid-column: 7 / 9;

				@include media-breakpoint-down(xl) {
					grid-row: 4 / 5;
					grid-column: 3 / 5;
				}

				@include media-breakpoint-down(lg) {
					grid-row: 7 / 9;
					grid-column: 3 / 5;
				}
			}

			&.box-1,
			&.box-2 {
				h6:not(.overline) {
					@include media-breakpoint-down(xxl) {
						font-size: 1.7rem;
					}
				}
			}

			&.wide, &.box-1, &.box-2{
				align-items: flex-end;

				.news-text-wrapper{
					position: relative;
				}
			}
		}
	}
}
