.modal{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100svw;
    height: 100svh;

    &__overlay{
        position: absolute;
        z-index: 999;
        top: 0;
        left: 0;
        width: 100svw;
        height: 100svh;
        background-color: get-color(brand-purple,3);
        opacity: .8;
    }

    &__brand-svg{
        position: absolute;
        left: 0;
        top: 0;
    }

    &__close-btn{
        position: absolute;
        top: 1rem;
        right: 1rem;
    }

    &__inner{
        position: relative;
        z-index: 1000;
        width: 72%;
        height: auto;
        background-color: white;
        border-radius: 1rem;
        padding: 10rem 8rem 6rem 8rem;

        > * {
            text-align: center;
        }

        h6{
            margin-top: 4rem;
            margin-bottom: 1rem;
        }

        .buttons{
            margin-top: 3rem;
        }
    }
}