/* SERVICE SECTION  */

.section-service {
	background-color: get-color(brand-purple, 2);
	padding-bottom: 30vh;
	border-radius: 0 0 2rem 2rem;

	@include media-breakpoint-down(xl) {
		padding-bottom: 20svh;
		padding-top: 10svh;
	}

	.intro-box {
		gap: 2rem;

		.text-wrapper {
			gap: 1rem;
		}
	}

	.video-player {
		margin: auto;
		position: relative;
		width: 96vw;
		height: 100vh;
		border-radius: 5rem;
		overflow: hidden;

		display: flex;
		align-items: center;
		align-content: center;
		justify-content: center;

		@include media-breakpoint-down(xl) {
			border-radius: 2rem;
			height: 80svh;
		}

		@include media-breakpoint-down(lg) {
			border-radius: 2rem;
			height: 50svh;
		}

		img {
			position: absolute;
			object-fit: cover;
			width: 100%;
			height: auto;
			min-height: 100%;
		}
	}

	.flex-grid {
		margin-top: 10rem;
		row-gap: 7rem;
	}
}
